import React from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";

export default function UserSetting() {
return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
  >
<Header/>
    <div className="page-wrapper" style={{top:'57px'}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12">
            <div className="white-box">
              <div className="table-responsive">
                <table className="table no-wrap">
                  <thead>
                    <tr>
                      <th className="border-top-0">#</th>
                      <th className="border-top-0">USER NAME</th>
                      <th className="border-top-0">EMAIL</th>
                      <th className="border-top-0">PASSWORD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td className="txt-oflo">Inventive Technologies</td>
                      <td>admin@inventivetechnologies.com.bd</td>
                      <td className="txt-oflo">123456</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>)
}