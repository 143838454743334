import { Routes, Route } from 'react-router-dom';
import Home from '../components/user/Index';
import Contact from '../components/user/Contact';
import Products from '../components/user/Products';
import Search from '../components/user/Search';
import ProductDetails from '../components/user/ProductDetails';
import Login from '../components/auth/Login';
import Cart from '../components/user/Cart';
import OrderTrack from '../components/user/OrderTrack';
import NoPage from '../components/user/NoPage';

function Guest() {
    return (<>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/product-details/:id" element={<ProductDetails />} />
                    <Route path="/products/:lavel_02/:lavel_03/:price" element={<Products />} />
                    <Route path="/search/:price/:search" element={<Search />} />
                    <Route path="/order-track/" element={<OrderTrack />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/cart" element={<Cart />} />
				    <Route path="*" element={<NoPage />} />
                </Routes>
        </>);
}
export default Guest;
