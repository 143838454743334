import React, { useState, useEffect } from 'react';
import Header from "./Header";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios';

export default function Dashboard() {

const [todaySales, setTodaySales] = useState({});

const fetchTodaySales = async () => {
try {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/today-sales`);
  setTodaySales(response.data.data[0]);
} catch (error) {
  console.error('Error fetching user information:', error);
}
}; 

useEffect(() => {
fetchTodaySales();
}, []);


const [todayReceived, setTodayReceived] = useState({});

const fetchTodayReceived = async () => {
try {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/today-received`);
  setTodayReceived(response.data.data[0]);
} catch (error) {
  console.error('Error fetching user information:', error);
}
}; 

useEffect(() => {
fetchTodayReceived();
}, []);


const [todayOrder, setTodayOrder] = useState({});

const fetchTodayOrder = async () => {
try {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/today-order`);
  setTodayOrder(response.data.data[0]);
} catch (error) {
  console.error('Error fetching user information:', error);
}
}; 

useEffect(() => {
fetchTodayOrder();
}, []);


const [totalProducts, setTotalProducts] = useState({});

const fetchTotalProducts = async () => {
try {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/total-products`);
  setTotalProducts(response.data.data[0]);
} catch (error) {
  console.error('Error fetching user information:', error);
}
}; 

useEffect(() => {
fetchTotalProducts();
}, []);


const [totalCustomer, setTotalCustomer] = useState({});

const fetchTotalCustomer = async () => {
try {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/total-customer`);
  setTotalCustomer(response.data.data[0]);
} catch (error) {
  console.error('Error fetching user information:', error);
}
}; 

useEffect(() => {
fetchTotalCustomer();
}, []);


const [totalInvoice, setTotalInvoice] = useState({});

const fetchTotalInvoice = async () => {
try {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/total-invoice`);
  setTotalInvoice(response.data.data[0]);
} catch (error) {
  console.error('Error fetching user information:', error);
}
}; 

useEffect(() => {
fetchTotalInvoice();
}, []);


return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
  >
<Header/>
    <div className="page-wrapper" style={{ top: '57px', minHeight: 'calc(100vh)' }}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">Today's Sales</h3>
              <ul className="list-inline two-part d-flex align-items-center mb-0">
                <li>
                  <div id="sparklinedash">
                    <canvas
                      width={67}
                      height={30}
                      style={{
                        display: "inline-block",
                        width: 67,
                        height: 30,
                        verticalAlign: "top"
                      }}
                    />
                  </div>
                </li>
                <li className="ms-auto">
                  <span className="counter text-success">{todaySales.total_sale}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">Today's Received Amount</h3>
              <ul className="list-inline two-part d-flex align-items-center mb-0">
                <li>
                  <div id="sparklinedash2">
                    <canvas
                      width={67}
                      height={30}
                      style={{
                        display: "inline-block",
                        width: 67,
                        height: 30,
                        verticalAlign: "top"
                      }}
                    />
                  </div>
                </li>
                <li className="ms-auto">
                  <span className="counter text-success">{todayReceived.total_received}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">Today's No. of Invoice</h3>
              <ul className="list-inline two-part d-flex align-items-center mb-0">
                <li>
                  <div id="sparklinedash3">
                    <canvas
                      width={67}
                      height={30}
                      style={{
                        display: "inline-block",
                        width: 67,
                        height: 30,
                        verticalAlign: "top"
                      }}
                    />
                  </div>
                </li>
                <li className="ms-auto">
                  <span className="counter text-success">{todayOrder.totalOrder}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">Total No. of Products</h3>
              <ul className="list-inline two-part d-flex align-items-center mb-0">
                <li>
                  <div id="sparklinedash">
                    <canvas
                      width={67}
                      height={30}
                      style={{
                        display: "inline-block",
                        width: 67,
                        height: 30,
                        verticalAlign: "top"
                      }}
                    />
                  </div>
                </li>
                <li className="ms-auto">
                  <span className="counter text-purple">{totalProducts.total_product}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">Total No. of Clients</h3>
              <ul className="list-inline two-part d-flex align-items-center mb-0">
                <li>
                  <div id="sparklinedash2">
                    <canvas
                      width={67}
                      height={30}
                      style={{
                        display: "inline-block",
                        width: 67,
                        height: 30,
                        verticalAlign: "top"
                      }}
                    />
                  </div>
                </li>
                <li className="ms-auto">
                  <span className="counter text-purple">{totalCustomer.total_customers}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">Total No. of Invoice</h3>
              <ul className="list-inline two-part d-flex align-items-center mb-0">
                <li>
                  <div id="sparklinedash3">
                    <canvas
                      width={67}
                      height={30}
                      style={{
                        display: "inline-block",
                        width: 67,
                        height: 30,
                        verticalAlign: "top"
                      }}
                    />
                  </div>
                </li>
                <li className="ms-auto">
                  <span className="counter text-purple">{totalInvoice.total_orders}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
<NotificationContainer/>
   </> )
}