import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthUser(){
    const navigate = useNavigate();

    const getToken = () => {
    try {
        const tokenString = localStorage.getItem('token');
        if (tokenString) {
        const userToken = JSON.parse(tokenString);
        return userToken;
        }
        return null;
    } catch (error) {
        console.error('Error while parsing token:', error);
        return null;
    }
    };

    const getUser = () =>{
        const userString = localStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }

    const [token,setToken] = useState(getToken());
    const [user,setUser] = useState(getUser());

    const saveToken = (user,token) =>{
        localStorage.setItem('token',JSON.stringify(token));
        localStorage.setItem('user',JSON.stringify(user));

        setToken(token);
        setUser(user);
        navigate('/dashboard');
    }

    const logout = () => {
        localStorage.clear();
        navigate('/login');
    }

    const http = axios.create({
        baseURL:`${process.env.REACT_APP_SERVER_URL}/api`,
        headers:{
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`
        }
    });
    return {
        setToken:saveToken,
        token,
        user,
        getToken,
        http,
        logout
    }
}