import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Footer from "./Footer";

export default function ProductDetails() {

  useEffect(() => {
    $('.js-mega-menu').HSMegaMenu({
      event: 'hover',
      direction: 'horizontal',
      pageContainer: $('.container'),
      breakpoint: 767.98,
      hideTimeOut: 0,
    });
    $.HSCore.components.HSHeader.init($('#header'));
    $.HSCore.components.HSOnScrollAnimation.init('[data-animation]');
    $.HSCore.components.HSUnfold.init($('[data-unfold-target]'), {
      afterOpen: function () {
        $(this).find('input[type="search"]').focus();
      }
    });
    $.HSCore.components.HSFancyBox.init('.js-fancybox');
    var countdowns = $.HSCore.components.HSCountdown.init('.js-countdown', {
      yearsElSelector: '.js-cd-years',
      monthsElSelector: '.js-cd-months',
      daysElSelector: '.js-cd-days',
      hoursElSelector: '.js-cd-hours',
      minutesElSelector: '.js-cd-minutes',
      secondsElSelector: '.js-cd-seconds',
    });
    $.HSCore.components.HSMalihuScrollBar.init($('.js-scrollbar'));
    $.HSCore.components.HSFocusState.init();
    $.HSCore.components.HSValidation.init('.js-validate', {
      rules: {
        confirmPassword: {
          equalTo: '#signupPassword',
        }
      }
    });
    $.HSCore.components.HSShowAnimation.init('.js-animation-link');
    $.HSCore.components.HSFancyBox.init('.js-fancybox');
    $.HSCore.components.HSSlickCarousel.init('.js-slick-carousel');
    $.HSCore.components.HSGoTo.init('.js-go-to');
    $.HSCore.components.HSHamburgers.init('#hamburgerTrigger');
    $.HSCore.components.HSUnfold.init($('[data-unfold-target]'), {
      beforeClose: function () {
        $('#hamburgerTrigger').removeClass('is-active');
      },
      afterClose: function () {
        $('#headerSidebarList .collapse.show').collapse('hide');
      }
    });
    $('#headerSidebarList [data-toggle="collapse"]').on('click', function (e) {
      e.preventDefault();
      var target = $(this).data('target');
      if ($(this).attr('aria-expanded') === "true") {
        $(target).collapse('hide');
      } else {
        $(target).collapse('show');
      }
    });
    $.HSCore.components.HSUnfold.init($('[data-unfold-target]'));
  }, []);

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };


  useEffect(() => {
  fetchMenus();
  fetchItemsView();
}, []);

const [menuData, setMenuData] = useState([]);
const fetchMenus = async () => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/menu`);
  const data = await response.json();
  setMenuData(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

  const [cart, setCart] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    const cartFromLocalStorage = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(cartFromLocalStorage);
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    const count = cart.reduce((total, item) => total + 1, 0);
    const sum = cart.reduce((total, item) => total + item.quantity * item.sale_price, 0);
    setCartCount(count);
    setTotalSum(sum);
  }, [cart]);


    const addToCart = (product) => {
    const existingProductIndex = cart.findIndex((item) => item.id === product.id);

    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += 1;
      setCart(updatedCart);
    } else {
      setCart((prevCart) => [
        ...prevCart,
        { ...product, quantity: 1 },
      ]);
    }
    NotificationManager.success("Success...", "Add to Cart", 1000);
  };

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
    setCart(updatedCart);
  };

  const updateQuantity = (productId, newQuantity) => {
    if (newQuantity >= 1) {
      const updatedCart = cart.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      );
      setCart(updatedCart);
    }
  };

const productIdFromParams = parseInt(useParams().id);
const specificItemIndex = cart.findIndex((item) => item.id === productIdFromParams);
let specificItemQuantity = 0;
if (specificItemIndex !== -1) {
  specificItemQuantity = cart[specificItemIndex].quantity;
}



    const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Autoplay
    autoplaySpeed: 3000, // Autoplay speed in milliseconds
  };

  const topOfPageRef = useRef(null);

  useEffect(() => {
    if (topOfPageRef.current) {
      topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

const id = useState(useParams().id);
const [details, setDetails] = useState([]);
const fetchItemsView = async () => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/items-view/${id}`);
  const data = await response.json();
  setDetails(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};


    const navigate = useNavigate();
    const handleSubmit = (event) => {
    event.preventDefault();
    const searchTerm = event.target.elements.search.value;
    navigate(`/search/price/${searchTerm}`);
  };

return(
<>
<header id="header" className="u-header u-header-left-aligned-nav" ref={topOfPageRef}>
   <div className="u-header__section">
      <div className="u-header-topbar py-2">
         <div className="container">
            <div className="d-flex align-items-center">
               <div className="topbar-left d-none d-xl-block">
                  <a className="text-gray-110 font-size-13 u-header-topbar__nav-link" >
                  Welcome to Inventive Technologies 
                   </a>
               </div>
               <div className="topbar-right ml-auto">
                  <ul className="list-inline mb-0">
                     <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                        <Link to="/contact" className="u-header-topbar__nav-link">
                        <i className="ec ec-map-pointer mr-1" /> Contact Us
                        </Link>
                     </li>
                     <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                        <Link to="/order-track/"
                           className="u-header-topbar__nav-link"
                           >
                        <i className="ec ec-transport mr-1" /> Track Your Order
                        </Link>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
      <div className="py-2 py-xl-5 bg-primary-down-lg" style={{background: 'radial-gradient(#fed70070, transparent)'}}>
         <div className="container my-0dot5 my-xl-0">
            <div className="row align-items-center">
               <div className="col-auto">
                  <nav className="navbar navbar-expand u-header__navbar py-0 justify-content-xl-between max-width-270 min-width-270">
                     <Link to="/home"
                        className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                        aria-label="Inventive Technologies"
                        >
                     <img src="../assets/logo.png"/>
                     </Link>
                     <button
                        id="sidebarHeaderInvokerMenu"
                        type="button"
                        className="navbar-toggler d-block btn u-hamburger mr-3 mr-xl-0"
                        aria-controls="sidebarHeader"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-unfold-event="click"
                        data-unfold-hide-on-scroll="false"
                        data-unfold-target="#sidebarHeader1"
                        data-unfold-type="css-animation"
                        data-unfold-animation-in="fadeInLeft"
                        data-unfold-animation-out="fadeOutLeft"
                        data-unfold-duration={500}
                        >
                     <span id="hamburgerTriggerMenu" className="u-hamburger__box">
                     <span className="u-hamburger__inner" />
                     </span>
                     </button>
                  </nav>
                  <aside
                     id="sidebarHeader1"
                     className="u-sidebar u-sidebar--left"
                     aria-labelledby="sidebarHeaderInvokerMenu"
                     >
                     <div className="u-sidebar__scroller">
                        <div className="u-sidebar__container">
                           <div className="u-header-sidebar__footer-offset pb-0">
                              <div className="position-absolute top-0 right-0 z-index-2 pt-4 pr-7">
                                 <button
                                    type="button"
                                    className="close ml-auto"
                                    aria-controls="sidebarHeader"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    data-unfold-event="click"
                                    data-unfold-hide-on-scroll="false"
                                    data-unfold-target="#sidebarHeader1"
                                    data-unfold-type="css-animation"
                                    data-unfold-animation-in="fadeInLeft"
                                    data-unfold-animation-out="fadeOutLeft"
                                    data-unfold-duration={500}
                                    >
                                 <span aria-hidden="true">
                                 <i className="ec ec-close-remove text-gray-90 font-size-20" />
                                 </span>
                                 </button>
                              </div>
                              <div className="js-scrollbar u-sidebar__body">
                                 <div
                                    id="headerSidebarContent"
                                    className="u-sidebar__content u-header-sidebar__content"
                                    >
                                    <a
                                       className="d-flex ml-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-vertical"
                                       href=""
                                       aria-label="Electro"
                                       >
                                    Menu
                                    </a>
                                    <ul
                                       id="headerSidebarList"
                                       className="u-header-collapse__nav"
                                       >
{menuData.map((item) => (

												   <li className="u-has-submenu u-header-collapse__submenu">
													  <b
														 className="u-header-collapse__nav-link u-header-collapse__nav-pointer"
														 href="javascript:;"
														 role="button"
														 data-toggle="collapse"
														 aria-expanded="false"
														 aria-controls={`headerSidebarHomeCollapse1${item.id}`}
														 data-target={`#headerSidebarHomeCollapse1${item.id}`}
														 >
													  {item.lavel_01}
													  </b>
													  <div
														 id={`headerSidebarHomeCollapse1${item.id}`}
														 className="collapse"
														 data-parent="#headerSidebarContent"
														 >
														 <ul
															id="headerSidebarHomeMenu"
															className="u-header-collapse__nav-list"
															>
														{item.lavel_02.map((subItem) => (
															<li>
															   <Link to={`/products/${subItem.lavel_02}/All/Price`}
																  className="u-header-collapse__submenu-nav-link"
																  href=""
																  >
															   {subItem.lavel_02}
															   </Link>
															</li> 
															))}
														 </ul>
													  </div>
												   </li>  
))}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </aside>
                  {/* ========== END HEADER SIDEBAR ========== */}
               </div>
               <div className="col d-none d-xl-block">
                  <form className="js-focus-state" onSubmit={handleSubmit}>
                     <label className="sr-only" htmlFor="searchproduct">
                     Search
                     </label>
                     <div className="input-group">
                        <input
                           type="text"
                           className="form-control py-2 pl-5 font-size-15 border-right-0 height-40 border-width-2 rounded-left-pill border-primary"
                           name="search"
                           placeholder="Search for Products"
                           required=""
                           />
                        <div className="input-group-append">
                           <button
                              className="btn btn-primary height-40 py-2 px-3 rounded-right-pill"
                              type="submit"
                              id="searchProduct1"
                              >
                           <span className="ec ec-search font-size-24" />
                           </button>
                        </div>
                     </div>
                  </form>
               </div>
               <div className="col col-xl-auto text-right text-xl-left pl-0 pl-xl-3 position-static">
                  <div className="d-inline-flex">
                     <ul className="d-flex list-unstyled mb-0 align-items-center">
                        <li className="col d-xl-none px-2 px-sm-3 position-static">
                           <a
                              id="searchClassicInvoker"
                              className="font-size-22 text-gray-90 text-lh-1 btn-text-secondary"
                              href="javascript:;"
                              role="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Search"
                              aria-controls="searchClassic"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-unfold-target="#searchClassic"
                              data-unfold-type="css-animation"
                              data-unfold-duration={300}
                              data-unfold-delay={300}
                              data-unfold-hide-on-scroll="true"
                              data-unfold-animation-in="slideInUp"
                              data-unfold-animation-out="fadeOut"
                              >
                           <span className="ec ec-search" />
                           </a>
                           <div
                              id="searchClassic"
                              className="dropdown-menu dropdown-unfold dropdown-menu-right left-0 mx-2"
                              aria-labelledby="searchClassicInvoker"
                              >
                              <form className="js-focus-state input-group px-3" onSubmit={handleSubmit}>
                                 <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search Product"
                                    name='search'
                                    />
                                 <div className="input-group-append">
                                    <button
                                       className="btn btn-primary px-3"
                                       type="submit"
                                       >
                                    <i className="font-size-18 ec ec-search" />
                                    </button>
                                 </div>
                              </form>
                           </div>
                        </li>
                        <li className="col d-xl-block">
                           <Link to="tel:+8801846844987" 
                              href=""
                              className="text-gray-90"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Support"
                              >
                           <i className="font-size-22 ec ec-support" />
                           </Link>
                        </li>

<li className="col pr-xl-0 px-2 px-sm-3  d-xl-block">
  <div
    id="basicDropdownHoverInvoker"
    className="text-gray-90 position-relative d-flex link"
    data-toggle="tooltip"
    data-placement="top"
    title="Cart"
    aria-controls="basicDropdownHover"
    aria-haspopup="true"
    aria-expanded="false"
    data-unfold-event="click"
    data-unfold-target="#basicDropdownHover"
    data-unfold-type="css-animation"
    data-unfold-duration={300}
    data-unfold-delay={300}
    data-unfold-hide-on-scroll="true"
    data-unfold-animation-in="slideInUp"
    data-unfold-animation-out="fadeOut"
  >
    <i className="font-size-22 ec ec-shopping-bag" />
    <span className="bg-lg-down-black width-22 height-22 bg-primary position-absolute d-flex align-items-center justify-content-center rounded-circle left-12 top-8 font-weight-bold font-size-12">
      {cartCount}
    </span>
    <span style={{width: '93px'}} className="d-none d-xl-block font-weight-bold font-size-16 text-gray-90 ml-3">
      BDT.{totalSum}
    </span>
  </div>
  <div 
    id="basicDropdownHover"
    className="cartScroll cart-dropdown dropdown-menu dropdown-unfold border-top border-top-primary mt-3 border-width-2 border-left-0 border-right-0 border-bottom-0 left-auto right-0"
    aria-labelledby="basicDropdownHoverInvoker"
  >
    <ul className="list-unstyled px-3 pt-3">
      <li className="border-bottom pb-3 mb-3">
        <div className="">
          <ul className="list-unstyled row mx-n2">
{cart.map((item) => (
  <>
    
            <li className="px-2 col-auto">
              <img
                className="img-fluid cart-image"
                 src={`${process.env.REACT_APP_SERVER_URL}/public/images/${item.pic_1}`}
              />
            </li>
            <li className="px-2 col">
            <Link to={`/product-details/${item.id}`} target='_blank'>
              <h5 className="text-blue font-size-14 font-weight-bold">
                {item.name}
              </h5>
            </Link>
              <span className="font-size-14">
       {item.quantity} x {item.sale_price}  &nbsp;&nbsp;
      <button style={{ border: 'none',width: '31px', borderRadius: '50px' }} onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
      &nbsp;&nbsp;
      <button style={{ border: 'none', width: '31px', borderRadius: '50px' }} onClick={() => updateQuantity(item.id, item.quantity - 1)}>-</button>
       <br/>BDT.{item.quantity * item.sale_price}
              </span>
     </li>
            <li className="px-2 col-auto">
              <div className="text-gray-90 link" onClick={() => removeFromCart(item.id)}>
                <i className="ec ec-close-remove" />
              </div>
    </li>
            <hr/>
</>
  ))}
            
          </ul>
        </div>
<b className=' font-weight-bold font-size-16 '>
TOTAL BDT.{totalSum}
</b>

    <br/>
      </li>
    </ul>

    <div className="flex-center-between px-4 pt-2 pb-10">.
    <Link to="/cart"
    className="btn btn-soft-secondary mb-3 mb-md-0 font-weight-normal px-5 px-md-4 px-lg-5">
    View cart
    </Link>

      <Link to="/cart"
        className="btn btn-primary-dark-w ml-md-2 px-5 px-md-4 px-lg-5"
      >
        Checkout
      </Link>
    </div>
  </div>
</li>


                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="d-none d-xl-block bg-primary">
         <div className="container">
            <div className="min-height-45">
               <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--wide u-header__navbar--no-space">
                  <div
                     id="navBar"
                     className="collapse navbar-collapse u-header__navbar-collapse"
                     >
                     <ul className="navbar-nav u-header__navbar-nav">
{menuData.map((item) => (
                        <li
                           className="nav-item hs-has-mega-menu u-header__nav-item"
                           data-event="hover"
                           data-animation-in="slideInUp"
                           data-animation-out="fadeOut"
                           data-position="left"
                           >
                           <b
                              id="homeMegaMenu"
                              className="nav-link u-header__nav-link u-header__nav-link-toggle"
                              href="javascript:;"
                              aria-haspopup="true"
                              aria-expanded="false"
                              >
                           {item.lavel_01}
                           </b>
                           <div
                              className="hs-mega-menu w-100 u-header__sub-menu"
                              aria-labelledby="homeMegaMenu"
                              >
                        <div className="row u-header__mega-menu-wrapper">
							          {item.lavel_02.map((subItem) => (
												 <div className="col-md-2">
                         <Link to={`/products/${subItem.lavel_02}/All/Price`}>
													<span className="u-header__sub-menu-title">
													{subItem.lavel_02}
													</span>
                          </Link>
													<ul className="u-header__sub-menu-nav-group">
													 {subItem.lavel_03.map((subSubItem) => (
													   <li>
														  <Link to={`/products/${subItem.lavel_02}/${subSubItem.lavel_03}/Price`}															 
															 className="nav-link u-header__sub-menu-nav-link"
															 >
														  {subSubItem.lavel_03}
														  </Link>
													   </li>  ))}
													</ul>
												 </div> 
									 ))}
								</div>
                           </div>
                        </li>	
))}
                     </ul>
                  </div>
               </nav>
            </div>
         </div>
      </div>
   </div>
</header>

  {/* ========== MAIN CONTENT ========== */}
  <main id="content" role="main" className='min-height-564'>
{details.map(product => (<>
  <div className="bg-gray-13 bg-md-transparent">
  <div className="container">
    {/* breadcrumb */}
    <div className="my-md-3">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb mb-3 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble">
          <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1">
           <Link to={`/home`} style={{backgroundColor: '#fff',}} >
            Home
           </Link>
          </li>
          <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1" >
          <Link to={`/products/${product.lavel_02}/All/Price`} style={{backgroundColor: '#fff',}} >
          {product.lavel_02}
          </Link>
          </li>
          <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1">
        <Link to={`/products/${product.lavel_02}/${product.lavel_03}/Price`} style={{backgroundColor: '#fff',}} >
          {product.lavel_03}
        </Link>
          </li>
        </ol>
      </nav>
    </div>
    {/* End breadcrumb */}
  </div>
</div>


<div>
    
<div className="container">
<div className="row">
  <div className="col-md-5 mb-4 mb-md-0">
      <Slider {...settings}>
          {product.product_img.map((product, featureIdx) => (
            <div key={featureIdx}>
            <img className='width100' 
            src={`${process.env.REACT_APP_SERVER_URL}/public/images/${product.img_url}`} 
            />
            </div>
            ))}
      </Slider>
  </div>
  <div className="col-md-7 mb-md-6 mb-lg-0">
    <div className="mb-2">
      <div className="border-bottom mb-3 pb-md-1 pb-3">
        <h2 className="font-size-25 text-lh-1dot2">
          {product.name}
        </h2>
        {/* <div className="mb-2">
          <a
            className="d-inline-flex align-items-center small font-size-15 text-lh-1"
            
          >
            <div className="text-warning mr-2">
              <small className="fas fa-star" />
              <small className="fas fa-star" />
              <small className="fas fa-star" />
              <small className="fas fa-star" />
              <small className="far fa-star text-muted" />
            </div>
            <span className="text-secondary font-size-13">
              (3 customer reviews)
            </span>
          </a>
        </div> */}
        <div className="d-md-flex align-items-center">
          <div className="text-gray-9 font-size-14">
            Availability:<span className="text-green font-weight-bold"> {product.stock_status} </span> || <strong>PRODUCT CODE</strong>: {product.code}
          </div>
        </div>
      </div>



      <div className="flex-horizontal-center flex-wrap">
        <b className="text-gray-6 font-size-20">
          Key Features
        </b>
      </div>
      <div className="mb-2">
          <ul className="font-size-12 p-0 text-gray-110 mb-4">
            {product.key_feature.map((feature, featureIdx) => (
                <li key={featureIdx} className="line-clamp-1 mb-1 list-bullet">
                    {feature.key_features}
                </li>
            ))}
          </ul>
      </div>

        <div className="d-flex align-items-baseline mb-4">
          <ins className="font-size-36 text-decoration-none" style={{color:'green',fontWeight:'bold'}}>BDT.{product.sale_price}</ins>
          <del className="font-size-20 ml-2 text-gray-6">BDT.{product.regular_price}</del>
        </div>

      <div className="d-md-flex align-items-end mb-3">
        <div className="max-width-150 mb-4 mb-md-0">
          {/* Quantity */}
          <div className="border rounded-pill py-2 px-3 border-color-1">
            <div className="js-quantity row align-items-center">
              <div className="col">
                <div
                  className="js-result form-control h-auto border-0 rounded p-0 shadow-none"
                  > Qty. {specificItemQuantity} </div>
              </div>
              <div className="col-auto pr-1">
                <a onClick={() => updateQuantity(product.id, specificItemQuantity - 1)}
                  className="js-minus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0"
                  href="javascript:;"
                >
                  <small className="fas fa-minus btn-icon__inner" />
                </a>
                <a onClick={() => addToCart(product)}
                  className="js-plus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0"
                  href="javascript:;"
                >
                  <small className="fas fa-plus btn-icon__inner" />
                </a>
              </div>
            </div>
          </div>
          {/* End Quantity */}
        </div>
       <div className="ml-md-3">
          <a onClick={() => addToCart(product)} className="btn px-5 btn-primary transition-3d-hover link">
            <i className="ec ec-add-to-cart mr-2 font-size-20" /> Add to Cart
          </a>
        </div>
      </div>
    </div>
  </div>
</div>



        <div className="row pt-7 pb-5">

          <div className="col-12 col-lg-12 col-xl-12 col-wd-12 pt-5 pt-md-0">
            <div className=" d-flex border-bottom border-color-1 mr-md-2">
              <h3 className="section-title section-title__full mb-0 pb-2 font-size-22">
                SPECIFICATION
              </h3>
            </div>
<p dangerouslySetInnerHTML={{ __html: product.specification }} />
          </div>

          
        </div>
      </div>
    </div>
</>
))}
  </main>
  <Footer/>
  <NotificationContainer/>
 </>
    )
}