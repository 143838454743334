import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Header from "./Header";
import axios from 'axios';
import Select from 'react-select';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Tab, Tabs } from 'react-bootstrap';

export default function AddCart() {
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };


  useEffect(() => {
}, []);

  const [cart, setCart] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    const cartFromLocalStorage = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(cartFromLocalStorage);
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    const count = cart.reduce((total, item) => total + 1, 0);
    const sum = cart.reduce((total, item) => total + item.quantity * item.sale_price, 0);
    setCartCount(count);
    setTotalSum(sum);
  }, [cart]);

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
    setCart(updatedCart);
  };

  const updateQuantity = (productId, newQuantity) => {
    if (newQuantity >= 1) {
      const updatedCart = cart.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      );
      setCart(updatedCart);
    }
  };

  const [c_id, setC_id] = useState();

  const handleC_id = (event) => {
    setC_id(event.target.value);
  };

  const [customer_names, setCustomer_names] =useState();

  const handleCustomer_names=(event)=>{
    setCustomer_names(event.target.value);
  }

  const [mobile, setMobile]=useState();

  const handleMobile = (event)=>{
    setMobile(event.target.value);
  }

  const [address, setAddress] =useState();
  
  const handleAddress =(event)=>{
    setAddress(event.target.value);
  }

  const [isSaving1, setIsSaving1] = useState(false)
  const customerSave = async () => {
    setIsSaving1(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/customer-save`, {
        c_id,
        customer_names,
        mobile,
        address,
      });
      setC_id('');
      setCustomer_names('');
      setMobile('');
      setAddress('');
      NotificationManager.success('Successfully...', 'Add');
      fetchClientList();
    } catch (error) {
      NotificationManager.error('Something wrong...');
    }
  setIsSaving1(false);
  };


  const [client_id, setClientList] = useState('Client ID');

  const handleClientIDCharge = (selectedDurationType) => {
  setClientList(selectedDurationType.label);
  };


  const [delivery_charge, setDeliveryCharge] = useState('0');

  const handleDeliveryCharge = (event) => {
    setDeliveryCharge(event.target.value);
  };
  
  
  const [pay, setPay] = useState('0');

  const handlePay = (event) => {
    setPay(event.target.value);
  };

  const [discount, setDiscount] = useState('0');

  const handleDiscount = (event) => {
    setDiscount(event.target.value);
  };

  const navigate = useNavigate();
	const [isSaving, setIsSaving] = useState(false)
  const placeOrder = async () => {
    setIsSaving(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/order-place-admin`, {
        client_id,
        delivery_charge,
        discount,
        pay,
        cart,
      });
        setCart([]);
        const orderId = response.data.order_id;
        navigate(`/invoice/${orderId}`);
    } catch (error) {
      console.error('Network error:', error);
    }
  setIsSaving(false);
  };


  const [optionClients, setClientsList] = useState([]);
  const fetchClientList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/c_id`);
      setClientsList(response.data);
    } catch (error) {
      NotificationManager.error('Customer not available');
    }
  };
    
  
useEffect(() => {
    fetchClientList();
  }, []);

return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
  >
<Header/>
    <div className="page-wrapper" style={{top:'57px'}}>
      <div className="container-fluid">
          <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12 scrollable-div">
            <div className="card white-box p-3"> 

{totalSum===0 ? <p>Empty Cart...</p> :<>
            <table className='table'>
            <tr><th>SUB TOTAL</th><th>:</th><th>{totalSum} Tk</th></tr>
            <tr>
            <th>Delivery Charge</th><th>:</th><th>
            <input type="text" 
            className='delivery-charge'
            value={delivery_charge} 
            onChange={handleDeliveryCharge}
            />
            </th>
           </tr>
            <tr>
            <th>Discount Amount</th><th>:</th><th>
            <input type="text" 
            className='delivery-charge'
            value={discount} 
            onChange={handleDiscount}
            />
            </th>
           </tr>

            <tr>
            <th>Pay</th><th>:</th><th>
            <input type="text" 
            className='delivery-charge'
            value={pay} 
            onChange={handlePay}
            />
            </th>
           </tr>
            <tr><th>TOTAL</th><th>:</th><th>{((totalSum + Number(delivery_charge))-Number(pay))-Number(discount)}/=</th></tr>
            </table>

          <div className=" d-flex border-bottom border-color-1 mr-md-2">
          <h3 className="section-title section-title__full mb-0 pb-2 font-size-22">
          CUSTOMER INFORMATION
          </h3>
          </div>
<br/>
<Tabs defaultActiveKey="Tab1">
		<Tab eventKey="Tab1" title="CUSTOMER">	
        <div className="col-12 col-lg-12 col-xl-12 col-wd-12">
        <label class="form-label mt-3">
        CUSTOMER-ID <sup style={{color:'red'}}>*</sup>
        </label>
        <Select
		    options={optionClients}
        value={client_id.label} 
        onChange={handleClientIDCharge} 
        placeholder="Search..."
        isSearchable
		    />
        </div>
        <div className="col-12 col-lg-12 col-xl-12 col-wd-12 mt-3">
<button class="btn btn-primary width100 link" onClick={placeOrder} disabled={isSaving}>Place Order</button>
</div>
		</Tab>

		<Tab eventKey="Tab2" title="CREATE FOR NEW CUSTOMER">
			<div className="col-12 col-lg-12 col-xl-12 col-wd-12">
            <label class="form-label mt-3">
            CUSTOMER-ID <sup style={{color:'red'}}>*</sup>
            </label>
            <input type="text" 
            class="form-control" 
            value={c_id}
            onChange={handleC_id}
            required
            />
</div>

<div className="col-12 col-lg-12 col-xl-12 col-wd-12">
            <label class="form-label mt-3">
            Name <sup style={{color:'red'}}>*</sup>
            </label>
            <input type="text" 
            class="form-control" 
            value={customer_names}
            onChange={handleCustomer_names}
            />
</div>
<div className="col-12 col-lg-12 col-xl-12 col-wd-12">
            <label class="form-label mt-3">
            Mobile <sup style={{color:'red'}}>*</sup>
            </label>
            <input type="text" 
            class="form-control" 
            value={mobile}
            onChange={handleMobile}
            />
</div>
<div className="col-12 col-lg-12 col-xl-12 col-wd-12">
           <label class="form-label mt-3">
             Delivery Address <sup style={{color:'red'}}>*</sup>
            </label>
            <div class="input-group">
            <textarea 
            class="form-control" 
            rows="2" 
            value={address}
            onChange={handleAddress}
            >
            </textarea>
            </div>
</div>
<div className="col-12 col-lg-12 col-xl-12 col-wd-12 mt-3">
<button class="btn btn-primary width100 link" onClick={customerSave} disabled={isSaving1}>Save</button>
</div>
		</Tab>
	</Tabs>


</>}


            </div>
          </div>
{totalSum===0 ? <p></p> :<>
          <div className="col-lg-4 col-md-12 col-sm-12 scrollable-div">
            <div className="card white-box p-0 ">
              <div className="card-heading">
                <h3 className="box-title mb-0">Cart List  (<span>{cartCount}</span>)</h3>
              </div>
              <div className="card-body">
    
    <ul className="chatonline">
      <li className="border-bottom pb-3 mb-3">
        <div className="">
          <ul className="list-unstyled row mx-n2">
{cart.map((item) => (
  <>
    
            <li className="px-2 col-auto">
              <img
              className="img-fluid cart-image"
              src={`${process.env.REACT_APP_SERVER_URL}/public/images/${item.pic_1}`}                
              />
            </li>
            <li className="px-2 col">
            <Link to={`/product-details/${item.id}`}>
              <h5 className="text-blue font-size-14 font-weight-bold">
                {item.name}
              </h5>
            </Link>
              <span className="font-size-14">
       {item.quantity} x {item.sale_price}  &nbsp;&nbsp;
      <button style={{ border: 'none',width: '31px', borderRadius: '50px' }} onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
      &nbsp;&nbsp;
      <button style={{ border: 'none', width: '31px', borderRadius: '50px' }} onClick={() => updateQuantity(item.id, item.quantity - 1)}>-</button>
       <br/>BDT.{item.quantity * item.sale_price}
              </span>
     </li>
            <li className="px-2 col-auto">
              <div className="text-gray-90 link" onClick={() => removeFromCart(item.id)}>
                <i className="ec ec-close-remove" />
              </div>
    </li>
            <hr style={{width: '92%'}}/>
</>
  ))}
            
          </ul>
        </div>
<b className=' font-weight-bold font-size-16 '>
TOTAL BDT.{totalSum}
</b>

    <br/>
      </li>
    </ul>

      <Link to="/add-cart/lavel_02/lavel_03/Price"
        className="btn btn-primary-dark-w ml-md-2 px-5 px-md-4 px-lg-5"
      >
        Continue shopping
      </Link>




              </div>
            </div>
          </div>
          </>}
        </div>
      </div>
    </div>
  </div>
      <NotificationContainer/>
</>)
}