import React, { useState, useRef, useEffect  } from "react";

import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams,Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

export default function CreateProduct() {
    const { id } = useParams();

  const navigate = useNavigate();
  const ref = useRef();

  const reset = () => {
    ref.current.value = "";
  };

  const [img_urls, setImages] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [uploading, setUploading] = useState(false);

  const handleImageChange = (e) => {
    setImages(Array.from(e.target.files));
  };

  const createProduct = async (e) => {
    e.preventDefault();

    if (img_urls.length === 0) {
      setValidationError({ img_urls: "Image(s) Required" });
      return;
    }

    setUploading(true); // Start uploading, disable button and show loading message
    const formData = new FormData();
    formData.append('product_id', id);

    img_urls.forEach((image, index) => {
      formData.append(`img_urls[${index}]`, image);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/image-upload`, formData);
      Swal.fire({
        icon: "success",
        text: response.data.message
      });
      setImages([]);
      setValidationError("");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setValidationError(error.response.data.errors);
      } else {
        Swal.fire({
          text: error.response.data.message || "An error occurred",
          icon: "error"
        });
      }
    } finally {
      setUploading(false); // Upload finished, enable button and hide loading message
    }
  };


const [product_imgs_view, setProduct_imgs_view] = useState([]);

const fetchProductImage = async (id) => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product-image-view/${id}`);
  const data = await response.json();
  setProduct_imgs_view(data.data);
  fetchProductImage(id);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

useEffect(() => {
  fetchProductImage(id);
}, []);


  const handleDeleteProductImage = (id) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this item?');

  if (isConfirmed) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-product-image/${id}`)
      .then(function (response) {
        fetchProductImage(productId);
        NotificationManager.success('Success...');
      })
      .catch(function (error) {
        console.error('Error deleting item:', error);
      });
  }
};

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
        <br/>
<div className="card">
      <div className="card-body">
        <h4 className="card-title">PRODUCT IMAGE</h4>
        <div className="form-wrapper">
          <Form onSubmit={createProduct}>
            <Form.Group controlId="img_urls" className="mb-3">
              <Form.Control type="file" multiple onChange={handleImageChange} ref={ref} />
              {validationError.img_urls && <div className="text-danger">{validationError.img_urls}</div>}
            </Form.Group>
            <br />
            <button className="form-control" variant="primary" onClick={reset} type="submit" disabled={uploading}>
              {uploading ? "Uploading..." : "UPLOAD"}
            </button>
          </Form>
        </div>
      </div>
    </div>


      {product_imgs_view.map(item => (
<>
    <Link className="link" style={{color:'red',fontWeight:'bold'}} onClick={() => handleDeleteProductImage(item.id)}>X</Link> &nbsp;
      <img
      src={`${process.env.REACT_APP_SERVER_URL}/public/images/${item.img_url}`}
      style={{ maxWidth: '100px',marginRight: '20px' }}
      /> &nbsp;
</>
      ))}


        </div>
      </div>
    </div>
  );
}
