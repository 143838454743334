import { useEffect } from 'react';
import AuthUser from './AuthUser';
import { Link } from 'react-router-dom';
const Header = () => {
    const {logout} = AuthUser();
    const handleLogout = () => {
    logout();
    };

      useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
    <header className="topbar" data-navbarbg="skin5">
      <nav className="navbar top-navbar navbar-expand-md navbar-dark">
        <div className="navbar-header" data-logobg="skin6">
          <Link className="navbar-brand" to="/dashboard">
            <img src="/assets/logo.png" alt="homepage" style={{width:'100%'}} />
          </Link>
          <a
            className="nav-toggler waves-effect waves-light text-dark d-block d-md-none"
            href="javascript:void(0)"
          >
            <i className="ti-menu ti-close" />
          </a>
        </div>
        <div
          className="navbar-collapse collapse"
          id="navbarSupportedContent"
          data-navbarbg="skin5"
        >
          <ul className="navbar-nav ms-auto d-flex align-items-center">
            <li>
              <div className="profile-pic">
                <img
                  src="/images/users/varun.jpg"
                  alt="user-img"
                  width={36}
                  className="img-circle"
                />
              <Link onClick={handleLogout}  to="/login">
                <span className="text-white font-medium">Logout</span>
              </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>  
    <aside className="left-sidebar" data-sidebarbg="skin6">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav">
            <li className="sidebar-item pt-2">
              <Link to="/dashboard"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                href="dashboard.html"
                aria-expanded="false"
              >
                <i className="far fa-clock" aria-hidden="true" />
                <span className="hide-menu">Dashboard</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/add-cart/lavel_02/lavel_03/Price"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                aria-expanded="false"
              >
                <i className="fa fa-user" aria-hidden="true" />
                <span className="hide-menu">Add to Cart</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/online-orders"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                aria-expanded="false"
              >
                <i className="fa fa-table" aria-hidden="true" />
                <span className="hide-menu">Orders & Sales</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/customers"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                aria-expanded="false"
              >
                <i className="fa fa-font" aria-hidden="true" />
                <span className="hide-menu">Customers</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/products/lavel_02/lavel_03/Price"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                aria-expanded="false"
              >
                <i className="fa fa-globe" aria-hidden="true" />
                <span className="hide-menu">Products</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/menu-setting"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                aria-expanded="false"
              >
                <i className="fa fa-columns" aria-hidden="true" />
                <span className="hide-menu">Menu Setting</span>
              </Link>
            </li>
            {/* <li className="sidebar-item">
              <Link to="/tag-setting"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                aria-expanded="false"
              >
                <i className="fa fa-columns" aria-hidden="true" />
                <span className="hide-menu">Filter & Tag Setting</span>
              </Link>
            </li> */}
            <li className="sidebar-item">
              <Link to="/user-setting"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                aria-expanded="false"
              >
                <i className="fa fa-info-circle" aria-hidden="true" />
                <span className="hide-menu">User Setting</span>
              </Link>
            </li>

          </ul>
        </nav>
      </div>
<footer>2021 © All right received ARZA IT</footer>
    </aside>  
  </>
  );
};

export default Header;
