import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";

export default function Customers() {

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const [search, setSearch]=useState('');
  
  const handleSearch=(event)=>{
      setSearch(event.target.value);
      setCurrentPage(1);
  }

  const [status, setStatus]=useState(1);

  const handleStatus =(event)=>{
    setStatus(event.target.value);
  }

  const [filteredItems, setItems] = useState([]); 


  const fetchCustomerItems = async (page) => {
    let searchValue = 'All';
    if (search && search.trim().length > 0) {
        searchValue = search;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/customer-list/${searchValue}/${status}/${page}`);
        const data = await response.json();
        setItems(data.data.data);
        setTotalPages(data.data.last_page);
        setCurrentPage(data.data.current_page);
        setTotalItems(data.data.total);
    } catch (error) {
        console.error("Error fetching data from the API:", error);
    }
};

useEffect(() => {
    fetchCustomerItems(currentPage);
}, [search, status]);

return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
  >
<Header/>
    <div className="page-wrapper" style={{top:'57px'}}>
      <div className="container-fluid">




        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12">
            <div className="white-box">
              <div className="d-md-flex mb-3">
                <h3 className="box-title mb-0">CUSTOMER LIST</h3>
                <div className="col-md-3 col-sm-4 col-xs-6 ms-auto">
                  <select className="form-select shadow-none row border-top"   
                  value={status}
                  onChange={handleStatus}
                  >
                    <option value='1'>Offline Client</option>
                    <option value='2'>Online Client</option>
                  </select>
                </div>
              </div>

                <input 
  type="text" 
  placeholder="Search ..."
  className="form-control" 	
  value={search}
  onChange={handleSearch}/>
  <br/>
              <div className="table-responsive">
                <table className="table no-wrap">
  <thead>
  <tr>
  <th className="border-top-0">#</th>
  <th className="border-top-0">CUSTOMER NAME</th>
  <th className="border-top-0">C.CODE</th>
  <th className="border-top-0">MOBILE</th>
  <th className="border-top-0">ADDRESS</th>
  </tr>
  </thead>
  <tbody>
  {filteredItems.map((product, idx) => (<>
  <tr>
  <td>{idx+1}</td>
  <td className="txt-oflo"> {product.customer_names}</td>
  <td>{!product.c_id ? '---' : product.c_id}</td>
  <td className="txt-oflo">{product.mobile}</td>
  <td><span className="text-success">{product.address}</span></td>
  </tr></>))}
  </tbody>
  </table>
  <nav
  className="d-md-flex justify-content-between align-items-center border-top pt-3"
  aria-label="Page navigation example"
  >

  <ul className="pagination mb-0 pagination-shop justify-content-center justify-content-md-start">
  &nbsp;&nbsp;  
  <li className="page-item">
  <button className="btn btn-primary link" onClick={() => fetchCustomerItems(currentPage - 1)} disabled={currentPage === 1}> Previous</button>
  </li>
  <li className="page-item">
  <button className="btn btn-primary link" onClick={() => fetchCustomerItems(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
  </li>
  </ul>
  <div className="text-center text-md-left mb-3 mb-md-3">
  Showing {totalItems} || Page {currentPage}/{totalPages} &nbsp;&nbsp;
  </div>
  </nav>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</>)
}