import React, { useEffect, useState } from 'react';

const InstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallDialog, setShowInstallDialog] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallDialog(true); // Show the install dialog when the event is triggered
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          setShowInstallDialog(false); // Hide the install dialog after installation
        } else {
          console.log('User dismissed the install prompt');
          setShowInstallDialog(false); // Hide the install dialog after dismissal
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <div>
      {showInstallDialog && (
        <div className="install-dialog">
          <h2>Install App</h2>
          <p>Would you like to install?</p>
          <button className='btn btn link' onClick={handleInstallClick}>Install</button>
          <button className='btn btn link' onClick={() => setShowInstallDialog(false)}>Not Now</button>
        </div>
      )}
    </div>
  );
};

export default InstallPrompt;
