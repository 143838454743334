import { useState } from "react"
import { Link } from 'react-router-dom';
import AuthUser from './AuthUser';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function Login() {
    const {http,setToken} = AuthUser();
    const [email,setEmail] = useState();
    const [password,setPassword] = useState();
	const [isSaving, setIsSaving] = useState(false)

    const submitForm = () =>{
	setIsSaving(true);
	http.post('/login', { email: email, password: password })
	  .then((res) => {
		setToken(res.data.user, res.data.access_token);
        NotificationManager.success('Success...');
	  })
  .catch((err) => {
	setIsSaving(false);
    NotificationManager.error('Something wrong');
  });
    }

    return(<>
    <div className="row justify-content-center pt-5">
            <div className="col-sm-6">
                <div className="card p-4">
                <Link style={{width: 'fit-content', textAlign: 'center', color: 'blue', fontWeight: 700,}} to='/home'><i class="fa fa-arrow-left" aria-hidden="true"></i>Back Home</Link>
                    <h1 className="text-center mb-3">ADMIN </h1>
                    <div className="form-group">
                        <label>Email address:</label>
                        <input type="email" className="form-control" placeholder="Enter email"
                            onChange={e=>setEmail(e.target.value)}
                        id="email" />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password:</label>
                        <input type="password" className="form-control" placeholder="Enter password"
                            onChange={e => setPassword(e.target.value)}
                        id="pwd" />
                    </div>
                    <button type="button"  disabled={isSaving} onClick={submitForm} className="btn btn-primary mt-4">Login {isSaving}</button>
                </div>
            </div>
        </div>
<NotificationContainer/>
</>
    )
}