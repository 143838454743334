import React, { useState, useEffect } from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import Header from "./Header";
import { Button, Modal,Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function OnlineOrders() {


const [orders_type_online_offline, setOrders_type_online_offline]= useState();

const handleOrders_type_online_offline=(event)=>{
setOrders_type_online_offline(event.target.value);
}

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const [search, setSearch]=useState('');

  const handleSearch = (event)=>{
    setSearch(event.target.value);
    setCurrentPage(1);
  }

  const [status, setStatus] = useState(0);

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };


const [items, setItems] = useState([]);

const fetchItems = async (page) => {
    let searchValue = 'All';
    if (search && search.trim().length > 0) {
        searchValue = search;
    }


	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/order-list/${orders_type_online_offline}/${searchValue}/${status}/${page}`);
        const data = await response.json();
        setItems(data.data.data);
        setTotalPages(data.data.last_page);
        setCurrentPage(data.data.current_page);
        setTotalItems(data.data.total);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

useEffect(() => {
  fetchItems(currentPage);
}, [orders_type_online_offline, search,status]);


  const [month, setMonth] = useState('01'); // Initial value is January

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };


  const [year, setYear] = useState('2023'); // Initial value is January

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };


const [sales, setSales] = useState([]);

const fetchSales = async () => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/monthly-sales-report/${month}/${year}`);
  const data = await response.json();
  setSales(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

useEffect(() => {
  fetchSales();
}, [month,year]);

  const totalSale = sales.reduce((acc, item) => acc +  Number(item.total_sale), 0);
  const totalDiscount = sales.reduce((acc, item) => acc + Number(item.total_discount), 0);
  const totalPay = sales.reduce((acc, item) => acc +  Number(item.total_pay), 0);
  const totalDeliveryCharge = sales.reduce((acc, item) => acc +  Number(item.total_delivery_charge), 0);


  const [p_month, setP_Month] = useState('01'); // Initial value is January

  const handleP_MonthChange = (event) => {
    setP_Month(event.target.value);
  };


  const [p_year, setP_Year] = useState('2023'); // Initial value is January

  const handleP_YearChange = (event) => {
    setP_Year(event.target.value);
  };

  const [paymentHistories, setPaymentHistories] = useState([]);

const fetchPaymentHistories = async () => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/monthly-payment-received-report/${p_month}/${p_year}`);
  const data = await response.json();
  setPaymentHistories(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

useEffect(() => {
  fetchPaymentHistories();
}, [p_month,p_year]);


const totalPayAmount = paymentHistories.reduce((sum, item) => sum + Number(item.pay_amount), 0);

  const [due_month, setDueMonth] = useState('01');
  const [due_year, setDueYear] = useState('2023');
  const [dueHistory, setDueHistory] = useState([]);

  const handleDueMonthChange = (event) => {
    setDueMonth(event.target.value);
  };

  const handleDueYearChange = (event) => {
    setDueYear(event.target.value);
  };

const [customer_names, setCustomerNameSearch] = useState({ label: 'Search Customer', value: 'Search Customer' });

const handleCustomerNameSearch =(event)=>{
  setCustomerNameSearch(event);
}

  const [optionCustomer, setOptionCustomer] = useState([]);
  const fetchOptionCustomer = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/option-customer-search`);
      setOptionCustomer(response.data);
    } catch (error) {
      NotificationManager.error('Not found');
    }
  };

  useEffect(() => {
  fetchOptionCustomer();
}, []);

 const [dueFilter, setDueFilter] = useState();
 
 const handleDueFilter=(event)=>{
  setDueFilter(event.target.value);
 }


  const fetchDueHistory = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/sales-and-due/${due_month}/${due_year}/${customer_names.value}/${dueFilter}`);
      const data = await response.json();
      const historyArray = Object.values(data.data);
      setDueHistory(historyArray);
    } catch (error) {
      console.error('Error fetching user information:', error);
    }
  };

  useEffect(() => {
    fetchDueHistory();
  }, [due_month,due_year,customer_names,dueFilter]);

  const handleOnLoad=()=>{
        fetchDueHistory();
  }

const [searchInvoice, setSearchInvoice] = useState('');
  const navigate = useNavigate();

  const handleSearchInvoice = (event) => {
    setSearchInvoice(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchInvoice) {
      window.open(`/invoice/${searchInvoice}`, '_blank');
    }
  };

const handleReset = () => {
    setSearchInvoice('');
  };

return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
  >
<Header/>
    <div className="page-wrapper" style={{top:'57px'}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12">
            <div className="white-box">
            	<Tabs defaultActiveKey="Tab1">
		<Tab eventKey="Tab1" title="ORDERS"><br/>
			              <div className="d-md-flex mb-3">
                <h3 className="box-title mb-0">ORDER DETAILS</h3>
              
              <div className="col-md-3 col-sm-4 col-xs-6 ms-auto">
                 <input type='text'
                  value={search}
                  onChange={handleSearch}
                  className='form-control'
                  placeholder='Search...'
                 />
                </div>

                <div className="col-md-3 col-sm-4 col-xs-6 ms-auto">
<select
  value={status} 
  onChange={handleStatus}
  className='form-control'
  >
    <option value={0}>Processing</option>
    <option value={1}>On Delivery</option>
    <option value={2}>Delivered</option>
</select>
                </div>

              <div className="col-md-3 col-sm-4 col-xs-6 ms-auto">
                  <select 
                  className="form-select shadow-none row border-top"
                  onChange={handleOrders_type_online_offline}
                  value={orders_type_online_offline}
                  >
                    <option value='0'>Offline Order</option>
                    <option value='1'>Online Order</option>
                  </select>
                </div>

              </div>
              <div className="table-responsive">

                <table className="table no-wrap">
                  <thead>
                    <tr>
                      <th className="border-top-0">#INV</th>
                      <th className="border-top-0">CUSTOMER</th>
                      <th className="border-top-0">MOBILE</th>
                      <th className="border-top-0">INV DATE</th>
                      <th className="border-top-0">VIEW</th>
                    </tr>
                  </thead>
                  <tbody>
{items.map(item => (
<tr key={item.id}>
<td>{item.id}</td>
<td>{item.customer.customer_names}</td>
<td>{item.customer.mobile}</td>
<td>{item.order_date}</td>
<td><Link to={`/invoice/${item.id}`} target='_blank' style={{color:'blue'}}>Invoice</Link></td>
</tr>
))}
</tbody>
</table>

<nav
  className="d-md-flex justify-content-between align-items-center border-top pt-3"
  aria-label="Page navigation example"
  >

  <ul className="pagination mb-0 pagination-shop justify-content-center justify-content-md-start">
  &nbsp;&nbsp;  
  <li className="page-item">
  <button className="btn btn-primary link" onClick={() => fetchItems(currentPage - 1)} disabled={currentPage === 1}> Previous</button>
  </li>
  <li className="page-item">
  <button className="btn btn-primary link" onClick={() => fetchItems(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
  </li>
  </ul>
  <div className="text-center text-md-left mb-3 mb-md-3">
  Showing {totalItems} || Page {currentPage}/{totalPages} &nbsp;&nbsp;
  </div>
  </nav>
              </div>
		</Tab>

		<Tab eventKey="Tab2" title="SALES REPORT"><br/>
<div className="row justify-content-center">
<h3 className="box-title mb-0">SALES REPORT</h3>
   <select value={year} onChange={handleYearChange} className='form-control' style={{borderRadius:'0px'}}>
      <option value='2023'>2023</option>
      <option value='2024'>2024</option>
      <option value='2025'>2025</option>
    </select>


   <select value={month} onChange={handleMonthChange} className='form-control' style={{borderRadius:'0px'}}>
      <option value='01'>January</option>
      <option value='02'>February</option>
      <option value='03'>March</option>
      <option value='04'>April</option>
      <option value='05'>May</option>
      <option value='06'>June</option>
      <option value='07'>July</option>
      <option value='08'>August</option>
      <option value='09'>September</option>
      <option value='10'>October</option>
      <option value='11'>November</option>
      <option value='12'>December</option>
    </select>



<table className='table'>
      <thead>
        <tr>
          <th>ORDER DATE</th>
          <th style={{ textAlign: 'right' }}>SALES</th>
          <th style={{ textAlign: 'right' }}>DISCOUNT</th>
          <th style={{ textAlign: 'right' }}>PAY</th>
          <th style={{ textAlign: 'right' }}>DELIVERY CHARGE</th>
        </tr>
      </thead>
      <tbody>
        {sales.map(item => (
          <tr key={item.id}>
            <td>{item.order_date}</td>
            <td style={{ textAlign: 'right' }}>{item.total_sale}</td>
            <td style={{ textAlign: 'right' }}>{item.total_discount}</td>
            <td style={{ textAlign: 'right' }}>{item.total_pay}</td>
            <td style={{ textAlign: 'right' }}>{item.total_delivery_charge}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td style={{ textAlign: 'right' }}><b>TOTAL</b></td>
          <td style={{ textAlign: 'right' }}><b>{totalSale}/=</b></td>
          <td style={{ textAlign: 'right' }}><b>{totalDiscount}/=</b></td>
          <td style={{ textAlign: 'right' }}><b>{totalPay}/=</b></td>
          <td style={{ textAlign: 'right' }}><b>{totalDeliveryCharge}/=</b></td>
        </tr>
      </tfoot>
    </table>
</div>
		</Tab>
		<Tab eventKey="Tab3" title="PAYMENT RECEIVED"><br/>
<div className="row justify-content-center">
<h3 className="box-title mb-0">PAYMENT RECEIVED REPORT</h3>
   <select value={p_year} onChange={handleP_YearChange} className='form-control' style={{borderRadius:'0px'}}>
      <option value='2023'>2023</option>
      <option value='2024'>2024</option>
      <option value='2025'>2025</option>
    </select>


   <select value={p_month} onChange={handleP_MonthChange} className='form-control' style={{borderRadius:'0px'}}>
      <option value='01'>January</option>
      <option value='02'>February</option>
      <option value='03'>March</option>
      <option value='04'>April</option>
      <option value='05'>May</option>
      <option value='06'>June</option>
      <option value='07'>July</option>
      <option value='08'>August</option>
      <option value='09'>September</option>
      <option value='10'>October</option>
      <option value='11'>November</option>
      <option value='12'>December</option>
    </select>


<table className='table'>
      <thead>
        <tr>
          <th>PAYMENT DATE</th>
          <th style={{ textAlign: 'right' }}>RECEIVED AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        {paymentHistories.map(item => (
          <tr key={item.id}>
            <td>{item.date}</td>
            <td style={{ textAlign: 'right' }}>{item.pay_amount}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td style={{ textAlign: 'right' }}><b>TOTAL</b></td>
          <td style={{ textAlign: 'right' }}><b>{totalPayAmount}/=</b></td>
        </tr>
      </tfoot>
    </table>
</div>
    </Tab>

<Tab eventKey="Tab4" title="DUE INVOICE"><br/>


   <select value={due_month} onChange={handleDueMonthChange} className='form-control' style={{borderRadius:'0px'}}>
      <option value='01'>January</option>
      <option value='02'>February</option>
      <option value='03'>March</option>
      <option value='04'>April</option>
      <option value='05'>May</option>
      <option value='06'>June</option>
      <option value='07'>July</option>
      <option value='08'>August</option>
      <option value='09'>September</option>
      <option value='10'>October</option>
      <option value='11'>November</option>
      <option value='12'>December</option>
    </select>
   <select value={due_year} onChange={handleDueYearChange} className='form-control' style={{borderRadius:'0px'}}>
      <option value='2023'>2023</option>
      <option value='2024'>2024</option>
      <option value='2025'>2025</option>
    </select>

      <Select
		    options={optionCustomer}
        value={customer_names}
        onChange={handleCustomerNameSearch}
        placeholder="Main Menu"
        isSearchable
		/>

   <select value={dueFilter} onChange={handleDueFilter} className='form-control' style={{borderRadius:'0px'}}>
      <option value='All'>All Invoice</option>
      <option value='Due'>Due Invoice</option>
    </select>

<Link onClick={handleOnLoad} style={{float:'right'}}>Reload</Link>

 <table className='table'>
        <thead>
          <tr>
            <th>INVOICE</th>
            <th>ORDER DATE</th>
            <th style={{ textAlign: 'right' }}>TOTAL AMOUNT</th>
            <th style={{ textAlign: 'right' }}>DELIVERY CHARGE</th>
            <th style={{ textAlign: 'right' }}>DISCOUNT</th>
            <th style={{ textAlign: 'right' }}>TOTAL PAY</th>
            <th style={{ textAlign: 'right' }}>DUE</th>
          </tr>
        </thead>
        <tbody>
          {dueHistory.map(item => (
            <tr key={item.id}>
              <td><Link to={`/invoice/${item.order_id}`} target='_blank' style={{ color: 'blue' }}>({item.order_id}) Invoice</Link></td>
              <td>{item.order_date}</td>
              <td style={{ textAlign: 'right' }}>{item.total_sale}</td>
              <td style={{ textAlign: 'right' }}>{item.total_delivery_charge}</td>
              <td style={{ textAlign: 'right' }}>{item.total_discount}</td>
              <td style={{ textAlign: 'right' }}>{item.total_pay}</td>
              <td style={{ textAlign: 'right', color:'red',fontWeight:'bold' }}>{((Number(item.total_sale)+Number(item.total_delivery_charge))-(Number(item.total_discount)))-(Number(item.total_pay))}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Tab>

    
<Tab eventKey="Tab5" title="SEARCH INVOICE"><br/>
 <form onSubmit={handleSubmit}>
<table>
  <tr>
<td><h3 class="box-title mb-0">SEARCH INVOICE ID/ORDER ID</h3></td>
<td>
<input 
        type='number' 
        value={searchInvoice} 
        onChange={handleSearchInvoice}
        className='form-control'  
        placeholder='Invoice ID'
      />
</td>
<td>
  <button type="submit" className='form-control' >Search</button>
</td>
<td>
  <button type="submit" onClick={handleReset} className='form-control' >Reset</button>
</td>
  </tr>
</table>
    </form>


    </Tab>
	</Tabs>


            </div>
          </div>
        </div>

      </div>
    </div><br/>
  </div>
        <NotificationContainer/>

</>)
}