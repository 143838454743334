import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from "./Header";
import axios from 'axios';
import Select from 'react-select';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function MenuSetting() {

const [menuData, setMenuData] = useState([]);
const fetchMenus = async () => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/menu`);
  const data = await response.json();
  setMenuData(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

useEffect(() => {
  fetchMenus();
}, []);


const [lavel_01_in, setLavel_01] =useState();
const handleLavel_01=(event)=>{
  setLavel_01(event.target.value);
}

  const [isSaving, setIsSaving] = useState(false);
 
  const submitLavel_01 = async () => {
    setIsSaving(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/input-lavel-01`, {
      lavel_01_in,
      });
    setLavel_01('');
    NotificationManager.success('Success...');
    fetchMenus();
    fetchOptionLavel_01();
    } catch (error) {
    NotificationManager.warning('Something wrong');
    }
  setIsSaving(false);
  };


  const [optionLavel_01, setOptionLavel_01] = useState([]);
  const fetchOptionLavel_01 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/option-lavel-01`);
      setOptionLavel_01(response.data);
    } catch (error) {
      NotificationManager.error('Not found');
    }
  };

  useEffect(() => {
  fetchOptionLavel_01();
}, []);


const [sub_for_lavel_01, setSub_for_lavel_01] = useState({ label: 'Main Menu', value: 'Select Main Menu' });

const handleSubForLavel01 =(event)=>{
  setSub_for_lavel_01(event);
}

const [lavel_02_in,setLavel_02]=useState('');

const handleLavel02=(event)=>{
  setLavel_02(event.target.value);
}


  const [isSaving2, setIsSaving2] = useState(false);
 
  const submitLavel_02 = async () => {
    setIsSaving2(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/input-lavel-02`, {
      lavel_01_in: sub_for_lavel_01.value,
      lavel_02_in,
      });
    setLavel_02('');
    setSub_for_lavel_01('');
    NotificationManager.success('Success...');
    fetchOptionLavel_01();
    fetchMenus();
    } catch (error) {
    NotificationManager.warning('Something wrong');
    }
  setIsSaving2(false);
  };


  const [lavel_01_in_for_sub, setLavel_01_in_for_sub]=useState({ label: 'Main Menu', value: 'Select Main Menu' });

  const handleLavel_01_in_for_sub=(event)=>{
    setLavel_01_in_for_sub(event);
  }

const [lavel_02_in_for_sub, setLavel_02_in_for_sub] =useState({ label: 'Sub Menu', value: 'Select Sub Menu' });

const handleLavel_02_in_for_sub = (event)=>{
  setLavel_02_in_for_sub(event);
}

  const [optionLavel_02, setOptionLavel_02] = useState([]);
  const fetchOptionLavel_02 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/option-lavel-02/${lavel_01_in_for_sub.value}`);
      setOptionLavel_02(response.data);
    } catch (error) {
      NotificationManager.error('Not found');
    }
  };

useEffect(() => {
  fetchOptionLavel_02();
}, [lavel_01_in_for_sub,lavel_02_in_for_sub]);

const [lavel_03_in, setLavel_03_in] =useState();

const handleLavel_03 = (event)=>{
  setLavel_03_in(event.target.value);
}


  const [isSaving3, setIsSaving3] = useState(false);
 
  const submitLavel_03 = async () => {
    setIsSaving3(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/input-lavel-03`, {
      lavel_01_in: lavel_01_in_for_sub.value,
      lavel_02_in: lavel_02_in_for_sub.value,
      lavel_03_in,
      });
    setLavel_01_in_for_sub('');
    setLavel_02_in_for_sub('');
    setLavel_03_in('');
    NotificationManager.success('Success...');
    fetchMenus();
    } catch (error) {
    NotificationManager.warning('Something wrong');
    }
  setIsSaving3(false);
  };


const handleLavel01Delete = (id) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this item?');

  if (isConfirmed) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-lavel-01/${id}`)
      .then(function (response) {
        fetchMenus();
        fetchOptionLavel_01();
        fetchOptionLavel_02();
        NotificationManager.success('Success...');
      })
      .catch(function (error) {
        console.error('Error deleting item:', error);
      });
  }
};


const handleLavel02Delete = (id) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this item?');

  if (isConfirmed) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-lavel-02/${id}`)
      .then(function (response) {
        fetchMenus();
        fetchOptionLavel_01();
        fetchOptionLavel_02();
        NotificationManager.success('Success...');
      })
      .catch(function (error) {
        console.error('Error deleting item:', error);
      });
  }
};



const handleLavel03Delete = (id) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this item?');

  if (isConfirmed) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-lavel-03/${id}`)
      .then(function (response) {
        fetchMenus();
        fetchOptionLavel_01();
        fetchOptionLavel_02();
        NotificationManager.success('Success...');
      })
      .catch(function (error) {
        console.error('Error deleting item:', error);
      });
  }
};

return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
  >
<Header/>
    <div className="page-wrapper" style={{top:'57px'}}>
      <div className="container-fluid">
      
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">LEVEL-01</h3>
              <input 
              type='text'
              className='form-control'
                value={lavel_01_in}
                onChange={handleLavel_01}
                placeholder='Add Main Menu'
              />
              <br/>
              <button className='form-control' onClick={submitLavel_01}  disabled={isSaving}>Save</button>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">LEVEL-02</h3>
  <Select
		    options={optionLavel_01}
        value={sub_for_lavel_01}
        onChange={handleSubForLavel01}
        placeholder="Main Menu"
        isSearchable
		/>
<br/>
    <input 
      type='text'
      className='form-control'
      value={lavel_02_in}
      onChange={handleLavel02}
      placeholder='Add Sub Menu'
    />
<br/>
<button className='form-control' onClick={submitLavel_02}  disabled={isSaving2}>Save</button>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">LEVEL-03</h3>
      <Select
		    options={optionLavel_01}
        value={lavel_01_in_for_sub} 
        onChange={handleLavel_01_in_for_sub} 
        placeholder="Select Main Menu"
        isSearchable
		/>
<br/>
      <Select
		    options={optionLavel_02}
        value={lavel_02_in_for_sub} 
        onChange={handleLavel_02_in_for_sub} 
        placeholder="Select Sub Menu"
        isSearchable
		/>

<br/>
    <input 
      type='text'
      className='form-control'
      value={lavel_03_in}
      onChange={handleLavel_03}
      placeholder='Add Sub Menu'
    />
<br/>
<button className='form-control' onClick={submitLavel_03}  disabled={isSaving3}>Save</button>

            </div>
          </div>
        </div>

        <div className="row">

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card white-box p-0">

              <div className="card-body">
<table>
  <thead>
    <tr>
      <th>MAIN MENU</th>
      <th>SUB MENU</th>
    </tr>
  </thead>
  <tbody>
    {menuData.map((item) => (
      <tr key={item.id}>
        <td>
            [<Link className='link' style={{ color: 'red', fontSize: '10px' }} onClick={() => handleLavel01Delete(item.id)}>
              <i className="fa fa-times"></i>
            </Link>] <b>{item.lavel_01} </b>
        </td>
        <td>
          <table>
            <tbody>
              {item.lavel_02.map((subItem) => (
                <tr key={subItem.id}>
                  <td style={{width:'50%'}}>
                    [<Link className='link' style={{ color: 'red', fontSize: '10px' }} onClick={() => handleLavel02Delete(subItem.id)}>
                      <i className="fa fa-times"></i>
                    </Link>] {subItem.lavel_02}
                  </td>
                  <td>
                    <table>
                      <tbody>
                        {subItem.lavel_03.map((subSubItem) => (
                          <tr key={subSubItem.id}>
                            <td>
                              [<Link className='link' style={{ color: 'red', fontSize: '10px' }} onClick={() => handleLavel03Delete(subSubItem.id)}>
                                <i className="fa fa-times"></i>
                              </Link>] {subSubItem.lavel_03}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>

      </tr>
    ))}
  </tbody>
</table>



              </div>
            </div>
          </div>


        </div>
      </div>      <br/><br/>
    </div>
  </div>
<NotificationContainer/>
</>)
}