import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from "./Header";
import axios from 'axios';
import Select from 'react-select';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function MenuSetting() {


const [lavel_02_in,setLavel_02]=useState({ label: 'Main Menu', value: 'Select Main Menu' });

const handleLavel02=(event)=>{
  setLavel_02(event);
}


const[tag_types, setTag_types] =useState();

const handleTagTypes=(event)=>{
    setTag_types(event.target.value);
}

  const [isSaving2, setIsSaving2] = useState(false);
 
  const submitLavel_02 = async () => {
    setIsSaving2(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/add-tag_types`, {
      lavel_02_in: lavel_02_in.label,
      tag_types,
      });
    setLavel_02('');
    setTag_types('');
    fetchTags();
    fetchItems();
    NotificationManager.success('Success...');
    } catch (error) {
    NotificationManager.warning('Something wrong');
    }
  setIsSaving2(false);
  };


  const [optionLavel_02, setOptionLavel_02] = useState([]);
  const fetchOptionLavel_02 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/option-lavel-02-list`);
      setOptionLavel_02(response.data);
    } catch (error) {
      NotificationManager.error('Not found');
    }
  };

useEffect(() => {
  fetchOptionLavel_02();
}, []);



  const [tagsOption, setTags] = useState([]);
  const fetchTags = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/option-tag-list-filter`);
      setTags(response.data);
    } catch (error) {
      NotificationManager.error('Not found');
    }
  };

useEffect(() => {
  fetchTags();
}, []);

const [tags_in,setTags_in]=useState({ label: 'Main Menu', value: 'Select Main Menu' });

const handleTags_in=(event)=>{
    setTags_in(event)
}

const [filter, setFilter] = useState();

const handleFilter=(event)=>{
    setFilter(event.target.value);
}


  const [isSaving3, setIsSaving3] = useState(false);
 
  const submitSubFilter = async () => {
    setIsSaving3(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/add-sub-filter`, {
      tags_in: tags_in.value,
      filter,
      });
    setTags_in('');
    setFilter('');
    fetchItems();
    NotificationManager.success('Success...');
    } catch (error) {
    NotificationManager.warning('Something wrong');
    }
  setIsSaving3(false);
  };


const [items, setItems] = useState([]);

const fetchItems = async () => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/items-tag-all`);
  const data = await response.json();
  setItems(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

useEffect(() => {
  fetchItems();
}, []);


const handleDeleteFilterHeader = (id) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this item?');

  if (isConfirmed) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-filter-header/${id}`)
      .then(function (response) {
        NotificationManager.success('Success...');
        fetchTags();
        fetchItems();
      })
      .catch(function (error) {
        console.error('Error deleting item:', error);
      });
  }
};


const handleDeleteFilterSub = (id,name) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this item?');

  if (isConfirmed) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-filter-sub/${id}/${name}`)
      .then(function (response) {
        NotificationManager.success('Success...');
        fetchTags();
        fetchItems();
      })
      .catch(function (error) {
        console.error('Error deleting item:', error);
      });
  }
};


return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
  >
<Header/>
    <div className="page-wrapper" style={{top:'57px'}}>
      <div className="container-fluid">
      
        <div className="row justify-content-center">

          <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">CREATE FILTER HEADER</h3>
      <Select
		options={optionLavel_02}
        value={lavel_02_in} 
        onChange={handleLavel02} 
        placeholder="Select Menu"
        isSearchable
		/>

<br/>
<input type='text'
    value={tag_types}
    onChange={handleTagTypes}
    placeholder='Filter Header'
    className='form-control'
/>
<br/>
<button 
onClick={submitLavel_02} 
disabled={isSaving2} 
className='form-control'
>
Add
</button>
            </div>
          </div>


                    <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
              <h3 className="box-title">ADD SUB FILTER</h3>
      <Select
		options={tagsOption}
        value={tags_in} 
        onChange={handleTags_in} 
        placeholder="Select Menu"
        isSearchable
		/>

<br/>
<input type='text'
    value={filter}
    onChange={handleFilter}
    placeholder='Sub Filter'
    className='form-control'
/>
<br/>
<button 
onClick={submitSubFilter} 
disabled={isSaving3} 
className='form-control'
>
Add
</button>
            </div>
          </div>

                    <div className="col-lg-4 col-md-12">
            <div className="white-box analytics-info">
<h3 className="box-title">FILTER</h3>
<table>
  <thead>
    <tr>
      <th>Category</th>
      <th>Tags</th>
    </tr>
  </thead>
  <tbody>
    {items.map(item => (
      <tr key={item.category}>
        <td>
        [<Link onClick={() => handleDeleteFilterHeader(item.id)} style={{color:'red'}}><i class="fa fa-times"></i></Link>] {item.category}
       <br/> [{item.lavel_02}]
        </td>
        <td>
          <table>
            <tbody>
              {item.tags.map(tag => (
                <tr key={tag}>
                  <td> [<Link onClick={() => handleDeleteFilterSub(item.id, tag)} style={{color:'red'}}><i class="fa fa-times"></i></Link>] {tag}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    ))}
  </tbody>
</table>
 </div> </div>
        </div>

      </div>      <br/><br/>
    </div>
  </div>
<NotificationContainer/>
</>)
}