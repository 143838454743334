import { Link, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect,useRef } from 'react';
import Header from "./Header";

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Button, Modal  } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Products() {
  const topOfPageRef = useRef(null);
  useEffect(() => {
    if (topOfPageRef.current) {
      topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  

const [menuData, setMenuData] = useState([]);
const fetchMenus = async () => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/menu`);
  const data = await response.json();
  setMenuData(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};


//Filter and Product View
  const [filteredItems, setItems] = useState([]); 

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const { lavel_02 } = useParams();
  const { lavel_03 } = useParams();
  const { price } = useParams();


  const [search, setSearch] = useState('');
  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };


const fetchItemsFromAPI = async (page) => {
    let searchValue = 'All';

    if (search && search.trim().length > 0) {
        searchValue = search;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/products-list-admin/${lavel_02}/${lavel_03}/${price}/${searchValue}/${page}`);
        const data = await response.json();
        setItems(data.data.data);
        setTotalPages(data.data.last_page);
        setCurrentPage(data.data.current_page);
        setTotalItems(data.data.total);
    } catch (error) {
        console.error("Error fetching data from the API:", error);
    }
};

  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    navigate(`/products${selectedValue}/Price`);
  };


useEffect(() => {
    fetchItemsFromAPI(currentPage);
}, [lavel_02, lavel_03, price, search]);


const [lavel_01_in, setLavel_01] = useState({ label: 'Main Menu', value: 'Type' });

const handleLavel_01 =(event)=>{
  setLavel_01(event);
}

const [lavel_02_in, setLavel_02]= useState({ label: 'Sub Menu', value: 'Type' });

const handleLavel_02 = (event)=>{
setLavel_02(event);
}

const [lavel_03_in, setLavel_03] =useState({ label: 'Sub Menu', value: 'Type' });

const handleLavel_03 = (event)=>{
  setLavel_03(event);
}

const [name_in, setName] =useState();

const handleName = (event)=>{
  setName(event.target.value);
}

const [code_in,setCode]=useState();

const handleCode=(event)=>{
  setCode(event.target.value.toUpperCase());
}

const [buy_price_in, setBuy_price] =useState();

const handleBuy_price = (event)=>{
  setBuy_price(event.target.value);
}

const [sale_price_in, setSale_price] = useState();

const handleSale_price =(event)=>{
  setSale_price(event.target.value);
}

const [regular_price_in,setRegular_price] =useState();

const handleRegular_price =(event)=>{
  setRegular_price(event.target.value);
}

const [stock_qty_in, setStock_qty] =useState();

const handleStock_qty = (event)=>{
  setStock_qty(event.target.value);
}

const [stock_status_in, setStock_status] =useState();

const handleStock_status = (event)=>{
  setStock_status(event.target.value);
}

const [specification_in, setSpecification] =useState();

const handleSpecification = (event)=>{
  setSpecification(event.target.value);
}

const [featured_in, setFeatured] =useState();

const handleFeatured = (event)=>{
  setFeatured(event.target.value);
}



const [best_sales_in, setBest_sales] =useState();

const handleBest_sales = (event)=>{
  setBest_sales(event.target.value);
}

const [popular_items_in, setPopular_items] =useState();

const handlePopular_items = (event)=>{
  setPopular_items(event.target.value);
}

const [tags_in, setTags] =useState();

const handleTags = (event)=>{
  setTags(event.target.value);
}


    const [keyFeatures, setKeyFeatures] = useState([]); // Initialize with an empty string

    const handleKeyFeaturesChange = (index, value) => {
    const updatedKeyFeatures = [...keyFeatures];
    updatedKeyFeatures[index] = value;
    setKeyFeatures(updatedKeyFeatures);
  };

  const addKeyFeature = () => {
    setKeyFeatures([...keyFeatures, '']); // Add an empty string to the array
  };

  const removeKeyFeature = (index) => {
    const updatedKeyFeatures = [...keyFeatures];
    updatedKeyFeatures.splice(index, 1);
    setKeyFeatures(updatedKeyFeatures);
  };



  const [optionLavel_01, setOptionLavel_01] = useState([]);
  const fetchOptionLavel_01 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/option-lavel-01`);
      setOptionLavel_01(response.data);
    } catch (error) {
      NotificationManager.error('Not found');
    }
  };

  const [optionLavel_02, setOptionLavel_02] = useState([]);
  const fetchOptionLavel_02 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/option-lavel-02/${lavel_01_in.value}`);
      setOptionLavel_02(response.data);
    } catch (error) {
      NotificationManager.error('Not found');
    }
  };

    const [optionLavel_03, setOptionLavel_03] = useState([]);
  const fetchOptionLavel_03 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/option-lavel-03/${lavel_02_in.value}`);
      setOptionLavel_03(response.data);
    } catch (error) {
      NotificationManager.error('Not found');
    }
  };


useEffect(() => {
  fetchMenus();
  fetchOptionLavel_01();
  fetchOptionLavel_02();
  fetchOptionLavel_03();
}, [lavel_01_in,lavel_02_in]);


const handleDeleteProducts = (id) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this item?');
  if (isConfirmed) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-product/${id}`)
      .then(function (response) {
        fetchItemsFromAPI(currentPage);
        NotificationManager.success('Success...');
      })
      .catch(function (error) {
        console.error('Error deleting item:', error);
      });
  }
};

  const [productQty, setProductQty] =useState();

  const handleProductQty = (event)=>{
    setProductQty(event.target.value);
  }

  const [stock_status_in_update, setStock_status_in_update] =useState('In Stock');

  const handleStockStatusUpdate = (event)=>{
    setStock_status_in_update(event.target.value);
  }

 const [showModal, setShowModal] = useState(false);
 const handleClose = () => setShowModal(false);

 const [productId, setProductId] = useState();

  const handleShow = (productId) => {
  setProductId(productId);
  setShowModal(true);
};

  

  const [isSaving3, setIsSaving3] = useState(false);
  
  const handleQtyUpdate = async () => {
    setIsSaving3(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/update-product-qty`, {
      productId,
      productQty,
      stock_status_in_update,
      });
      setProductQty('');
      fetchItemsFromAPI(currentPage);
    } catch (error) {
      console.error('Network error:', error);
    }
  setIsSaving3(false);
  };



const [product_key_feature_view, setProduct_key_feature_view] = useState([]);

const fetchProductKeyFeature = async (productId) => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product-key-feature-view/${productId}`);
  const data = await response.json();
  setProduct_key_feature_view(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

useEffect(() => {
  fetchProductKeyFeature(productId);
}, []);



 const [showModal2, setShowModal2] = useState(false);
 const handleClose2 = () => setShowModal2(false);


const handleShow2 = async (productId) => {
    fetchProductKeyFeature(productId);
    setProductId(productId);
    setShowModal2(true);

    if (!productId) {
      setLavel_01('');
      setLavel_02('');
      setLavel_03('');
      setName('');
      setCode('');
      setBuy_price('');
      setSale_price('');
      setRegular_price('');
      setStock_qty('');
      setStock_status('');
      setSpecification('');
      setFeatured('');
      setBest_sales('');
      setPopular_items('');
      setTags('');
      setKeyFeatures([]);
    } else {
        try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/edit-product?productId=${productId}`);
      setLavel_01({label: response.data.data.lavel_01, value: response.data.data.lavel_01});
      setLavel_02({label: response.data.data.lavel_02, value: response.data.data.lavel_02});
      setLavel_03({label: response.data.data.lavel_03, value: response.data.data.lavel_03});
      setName(response.data.data.name);
      setCode(response.data.data.code);
      setBuy_price(response.data.data.buy_price);
      setSale_price(response.data.data.sale_price);
      setRegular_price(response.data.data.regular_price);
      setStock_qty(response.data.data.stock_qty);
      setStock_status(response.data.data.stock_status);
      setSpecification(response.data.data.specification);
      setFeatured(response.data.data.featured);
      setBest_sales(response.data.data.best_sales);
      setPopular_items(response.data.data.popular_items);
      setTags(response.data.data.tags);
        } catch (error) {
        setName('');
        }
    }
};

  const [isSaving, setIsSaving] = useState(false);
  
  const submitProduct = async () => {
    setIsSaving(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/add-products`, {
      productId,
      lavel_01_in: lavel_01_in.label,  
      lavel_02_in: lavel_02_in.label,
      lavel_03_in: lavel_03_in.label,
      name_in,
      code_in,
      buy_price_in,
      sale_price_in,
      regular_price_in,
      stock_qty_in,
      stock_status_in,
      specification_in,
      featured_in,
      best_sales_in,
      popular_items_in,
      tags_in,
      key_features_in: keyFeatures,
      });

      if(!productId){
      setLavel_01('');
      setLavel_02('');
      setLavel_03('');
      setName('');
      setCode('');
      setBuy_price('');
      setSale_price('');
      setRegular_price('');
      setStock_qty('');
      setStock_status('');
      setSpecification('');
      setFeatured('');
      setBest_sales('');
      setPopular_items('');
      setTags('');
      }
      setKeyFeatures([]);

      NotificationManager.success('Successfully updated...');
      fetchItemsFromAPI(currentPage);
      fetchProductKeyFeature(productId);
    } catch (error) {
      NotificationManager.error('Something wrong');
    }
  setIsSaving(false);
  };

  const handleDeleteProductKeyFeature= (id) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this item?');

  if (isConfirmed) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-product-key-feature/${id}`)
      .then(function (response) {
        fetchProductKeyFeature(productId);
        NotificationManager.success('Success...');
      })
      .catch(function (error) {
        console.error('Error deleting item:', error);
      });
  }
};

return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
     ref={topOfPageRef}
  >
<Header/>

  <div className="page-wrapper" style={{top:'57px'}}>
      <div className="container-fluid white-box">

  <div className="row">
  <div className="col-md-12 col-lg-12 col-sm-12">
  <div className="d-md-flex mb-3">
  <p className="col-md-5 col-sm-3 col-xs-6 ms-auto">
  <select 
  className="form-control" 
  id="menuSelect" 
  onChange={handleSelectChange} 
  value={selectedOption}
  >
  <option value='' selected="true" disabled="disabled">
  Select Menu
  </option>
  {menuData.map((item) => (
  <React.Fragment key={item.lavel_01}>
  <optgroup label={item.lavel_01}>
  {item.lavel_02.map((subItem) => (
  <React.Fragment key={subItem.lavel_02}>
  <option value={`/${subItem.lavel_02}/All`}>
  &nbsp; &nbsp; {subItem.lavel_02}
  </option>
  {subItem.lavel_03.map((subSubItem) => (
  <option
  key={subSubItem.lavel_03}
  value={`/${subItem.lavel_02}/${subSubItem.lavel_03}`}
  >
  &nbsp; &nbsp; &nbsp; &nbsp; {subSubItem.lavel_03}
  </option>
  ))}
  </React.Fragment>
  ))}</optgroup>
  </React.Fragment>
  ))}
  </select>
  </p>

  <p className="col-md-4 col-sm-4 col-xs-6 ms-auto">
  <input 
  type="text" 
  placeholder="Search ..."
  className="form-control" 	
  value={search}
  onChange={handleSearch}/>
  </p>

  <p className="col-md-3 col-sm-12 col-xs-6 ms-auto">
  <div className='form-control'>
  Price:  
  <Link to={`/products/${lavel_02}/${lavel_03}/Low`}  className={price === 'Low' ? 'link3' : 'link4'} > Low </Link> ||  
  <Link to={`/products/${lavel_02}/${lavel_03}/High`}  className={price === 'High' ? 'link3' : 'link4'}> High </Link>
  </div>
  </p>
  </div>
  <div className="table-responsive">
  <button variant="primary" onClick={() => handleShow2(null)}>ADD NEW</button>

  <table className="table no-wrap">
  <thead>
  <tr>
  <th className="border-top-0">#</th>
  <th className="border-top-0">PRODUCTS NAME</th>
  <th className="border-top-0">PCODE</th>
  <th className="border-top-0">BUY</th>
  <th className="border-top-0">SALE</th>
  <th className="border-top-0">REGULAR</th>
  <th className="border-top-0">STOCK </th>
  <th className="border-top-0">STATUS</th>
  <th className="border-top-0">IMG</th>
  <th className="border-top-0">THM</th>
  <th style={{width:'100px'}} className="border-top-0">ACTION</th>
  </tr>
  </thead>
  <tbody>
  {filteredItems.map((product, idx) => (<>
  <tr>
  <td>{idx+1}</td>
  <td className="txt-oflo"> {product.name.substring(0, 18)}...</td>
  <td>{product.code}</td>
  <td><span className="text-success">{product.buy_price}</span></td>
  <td><span className="text-success">{product.sale_price}</span></td>
  <td><span className="text-success">{product.regular_price}</span></td>
  <td className="txt-oflo">{product.stock_qty}</td>
  <td className="txt-oflo">{product.stock_status}</td>
  <td style={{textAlign:"center"}}><Link target="_blank" className="Link" to={`/product-image/${product.id}`}><i class="fa fa-image"></i></Link></td>
  <td style={{textAlign:"center"}}><Link target="_blank" className="Link" to={`/product-icon/${product.id}`}><i class="fa fa-image"></i></Link></td>
  <td className="txt-oflo">
  <button onClick={() => handleDeleteProducts(product.id)}>Delete</button> 

  <button variant="primary" onClick={() => handleShow(product.id)}>Stock</button>
  <button variant="primary" onClick={() => handleShow2(product.id)}>Edit</button>
  </td>
  </tr></>))}
  </tbody>
  </table>
  <nav
  className="d-md-flex justify-content-between align-items-center border-top pt-3"
  aria-label="Page navigation example"
  >

  <ul className="pagination mb-0 pagination-shop justify-content-center justify-content-md-start">
  &nbsp;&nbsp;  
  <li className="page-item">
  <button className="btn btn-primary link" onClick={() => fetchItemsFromAPI(currentPage - 1)} disabled={currentPage === 1}> Previous</button>
  </li>
  <li className="page-item">
  <button className="btn btn-primary link" onClick={() => fetchItemsFromAPI(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
  </li>
  </ul>
  <div className="text-center text-md-left mb-3 mb-md-3">
  Showing {totalItems} || Page {currentPage}/{totalPages} &nbsp;&nbsp;
  </div>
  </nav>
  </div>
  </div>
  </div>
      </div>
    </div>
  </div>

      <Modal show={showModal} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
      <Modal.Title>UPDATE STOCK</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="row">

      <p className="col-md-4 col-sm-4 col-xs-6 ">
      ADD STOCK QTY.
      <input className="form-control" type="number" value={productQty} onChange={handleProductQty}/>
      </p>

      <p className="col-md-4 col-sm-4 col-xs-6 ">
      STATUS
      <select
      value={stock_status_in_update} 
      onChange={handleStockStatusUpdate}
      className="form-control" 	
      >
      <option value='In Stock'>In Stock</option>
      <option value='Out of Stock'>Out of Stock</option>
      </select>
      </p>

      <p className="col-md-4 col-sm-4 col-xs-6 ">
      <br/>
      <Button style={{width:'100%'}} onClick={handleQtyUpdate}  disabled={isSaving3}>UPDATE</Button>
      </p>

      </div>
      </Modal.Body>
      </Modal>


      <Modal show={showModal2} onHide={handleClose2} backdrop="static" size="xl" >
      <Modal.Header closeButton>
      <Modal.Title>  {!productId ? 'ADD NEW PRODUCT' : 'UPDATE'} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="col-md-12 col-lg-12 col-sm-12">
      <div className="row">
      <p className="col-md-3 col-sm-3 col-xs-6 ">
      <b>MAIN MENU</b>
      <Select
      options={optionLavel_01}
      value={lavel_01_in} 
      onChange={handleLavel_01} 
      placeholder="Main Menu"
      isSearchable
      />
      </p>

      <p className="col-md-3 col-sm-3 col-xs-6 ">
      <b>SUB MENU</b>
      <Select
      options={optionLavel_02}
      value={lavel_02_in} 
      onChange={handleLavel_02} 
      placeholder="Sub Menu"
      isSearchable
      />
      </p>

      <p className="col-md-3 col-sm-3 col-xs-6 ">
      <b>SUB MENU</b>
      <Select
      options={optionLavel_03}
      value={lavel_03_in} 
      onChange={handleLavel_03} 
      placeholder="Sub Menu"
      isSearchable
      />
      </p>
      
      <p className="col-md-3 col-sm-3 col-xs-6 ">
      <b>PRODUCT CODE</b>
      <input 
      type="text" 
      className="form-control" 	
      value={code_in}
      onChange={handleCode}/>
      </p>


      <p className="col-md-12 col-sm-12 col-xs-12 ">
      <b>PRODUCT NAME</b>
      <input 
      type="text" 
      className="form-control" 	
      value={name_in}
      onChange={handleName}/>
      </p>


      <p className="col-md-2 col-sm-2 col-xs-6 ">
      <b>BUY PRICE</b>
      <input 
      type="number" 
      className="form-control" 	
      value={buy_price_in}
      onChange={handleBuy_price}/>
      </p>

      <p className="col-md-2 col-sm-2 col-xs-6 ">
      <b>SALE PRICE</b>
      <input 
      type="number" 
      className="form-control" 	
      value={sale_price_in}
      onChange={handleSale_price}/>
      </p>

      <p className="col-md-2 col-sm-2 col-xs-6 ">
      <b>REGULAR PRICE</b>
      <input 
      type="number" 
      className="form-control" 	
      value={regular_price_in}
      onChange={handleRegular_price}/>
      </p>

      <p className="col-md-2 col-sm-2 col-xs-6 ">
      <b>STOCK QTY.</b>
      <input 
      type="number" 
      className="form-control" 	
      value={stock_qty_in}
      onChange={handleStock_qty}/>
      </p>

      <p className="col-md-2 col-sm-2 col-xs-6 ">
      <b>STOCK STATUS</b>
      <select
      value={stock_status_in} 
      onChange={handleStock_status}
      className="form-control" 	
      >
      <option value=''></option>
      <option value='In Stock'>In Stock</option>
      <option value='Out of Stock'>Out of Stock</option>
      </select>
      </p>

      <p className="col-md-2 col-sm-2 col-xs-6 ">
      <b>FEATURED PRODUCT</b>
      <select
      value={featured_in} 
      onChange={handleFeatured}
      className="form-control" 	
      >
      <option value=''></option>
      <option value='Yes'>Yes</option>
      <option value='No'>No</option>
      </select>
      </p>


      <p className="col-md-2 col-sm-2 col-xs-6 ">
      <b>BEST SALES</b>
      <select
      value={best_sales_in} 
      onChange={handleBest_sales}
      className="form-control" 	
      >
      <option value=''></option>
      <option value='Yes'>Yes</option>
      <option value='No'>No</option>
      </select>
      </p>

      <p className="col-md-2 col-sm-2 col-xs-6 ">
      <b>OFFER PRODUCT</b>
      <select
      value={popular_items_in} 
      onChange={handlePopular_items}
      className="form-control" 	
      >
      <option value=''></option>
      <option value='Yes'>Yes</option>
      <option value='No'>No</option>
      </select>
      </p>

      <p className="col-md-8 col-sm-8 col-xs-12 ">
      <b>TAG'S</b> [Example: hp, core i3, 8GB... ] (Optional)
      <textarea 
      type="text" 
      className="form-control" 	
      value={tags_in}
      onChange={handleTags}/>
      </p>


      <p className="col-md-4 col-sm-6 col-xs-6">
      <b>KEY FEATURES</b><br/>
      {keyFeatures.map((keyFeature, index) => (
      <div key={index}>
      <table>
      <tr>
      <td>
      <input
      type="text"
      value={keyFeature}
      onChange={(e) => handleKeyFeaturesChange(index, e.target.value)}
      className="form-control" 	
      />
      </td> 
      <td>
      <button type="button" onClick={() => removeKeyFeature(index)}   className="form-control" 	>
        X
      </button>
      </td> 
      </tr>
      </table>
      </div>
      ))}
      <Link onClick={addKeyFeature} >
      Add
      </Link>
      <br/>
      {product_key_feature_view.map(item => (<>
      <Link className="link" style={{color:'red'}} onClick={() => handleDeleteProductKeyFeature(item.id)}>X</Link> {item.key_features} <br/>
      </>))}
      </p>

      <p className="col-md-8 col-sm-6 col-xs-12">
      <b>SPECIFICATION</b> [<Link target="_blank" to='https://wordhtml.com/'> Convert </Link>]

      <textarea 
      type="text" 
      className="form-control" 	
      value={specification_in}
      onChange={handleSpecification}
      style={{height:'300px'}}
      />


      {/* <ReactQuill
      theme="snow"
      value={specification_in}
      onChange={handleSpecification}
      style={{height:'150px'}}
      modules={{
      toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
      ],
      }}
      /> */}
      <br/>
      </p>

      <p className="col-md-12 col-sm-12 col-xs-12 ">
      <br/>
      <button style={{width:'100%'}} className="btn btn-primary"  onClick={submitProduct}  disabled={isSaving}>Save</button>
      </p>


      </div>
      </div>

      </Modal.Body>
      </Modal>

      <NotificationContainer/>
</>)
}