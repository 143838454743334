import React, { useState, useRef, useEffect  } from "react";

import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams,Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

export default function CreateProduct() {
    const { id } = useParams();

  const navigate = useNavigate();
  const ref = useRef();

  const reset = () => {
    ref.current.value = "";
  };

  const [img_urls, setImages] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [uploading, setUploading] = useState(false);

  const handleImageChange = (e) => {
    setImages(Array.from(e.target.files));
  };

const createProduct = async (e) => {
  e.preventDefault();
    setUploading(true); // Start uploading, disable button and show loading message

  const formData = new FormData();
  formData.append('product_id', id);
  formData.append('img_url', img_urls[0]); // Assuming only one image is allowed

  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/image-icon`, formData);
    Swal.fire({
      icon: "success",
      text: response.data.message
    });
    setImages([]);
    setValidationError("");
    setUploading(false); // Upload finished, enable button and hide loading message
  } catch (error) {
    if (error.response.status === 422) {
      setValidationError(error.response.data.errors);
    } else {
      Swal.fire({
        text: error.response.data.message,
        icon: "error"
      });
    }
  }
};



const [product_imgs_view, setProduct_imgs_view] = useState([]);

const fetchProductImage = async (id) => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product-icon-view/${id}`);
  const data = await response.json();
  setProduct_imgs_view(data.data);
  fetchProductImage(id);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

useEffect(() => {
  fetchProductImage(id);
}, []);


  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
        <br/>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">PRODUCT THUMBNAIL</h4>
              <div className="form-wrapper">
                <Form onSubmit={createProduct}>
                  <Form.Group controlId="img_urls" className="mb-3">
                    <Form.Control type="file" onChange={handleImageChange} ref={ref} />
                    {validationError.img_urls && <div className="text-danger">Image(s) Required</div>}
                  </Form.Group>
                  <br />
                  <button className="form-control" variant="primary" onClick={reset} type="submit">
                                  {uploading ? "Uploading..." : "UPLOAD"}
                  </button>
                </Form>
              </div>
            </div>
          </div>


{product_imgs_view.map(item => (
<>
      <img
      src={`${process.env.REACT_APP_SERVER_URL}/public/images/${item.pic_1}`}
      style={{ maxWidth: '100%'}}
      /> &nbsp;
</>
      ))}


        </div>
      </div>
    </div>
  );
}
