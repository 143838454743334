import { Routes, Route } from 'react-router-dom';
import Dashboard from "../components/auth/Dashboard";
import AddCart from "../components/auth/AddCart";
import OnlineOrders from "../components/auth/OnlineOrders";
import Invoice from "../components/auth/Invoice";
import Challan from "../components/auth/Challan";
import Customers from "../components/auth/Customers";
import Products from "../components/auth/Products";
import MenuSetting from "../components/auth/MenuSetting";
import UserSetting from "../components/auth/UserSetting";
import TagSetting from "../components/auth/TagSetting";
import PlaceOrder from "../components/auth/PlaceOrder";
import ProductImage from "../components/auth/ProductImage";
import Icon from "../components/auth/Icon";
import NoPage from "../components/auth/NoPage";

function Auth() {
    return (
        <>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/add-cart/:lavel_02/:lavel_03/:price" element={<AddCart />} />
                    <Route path="/place-order" element={<PlaceOrder />} />
                    <Route path="/online-orders" element={<OnlineOrders />} />
                    <Route path="/invoice/:order_id" element={<Invoice />} />
                    <Route path="/challan/:order_id" element={<Challan />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/products/:lavel_02/:lavel_03/:price" element={<Products />} />
                    <Route path="/menu-setting" element={<MenuSetting />} />
                    <Route path="/tag-setting" element={<TagSetting />} />
                    <Route path="/user-setting" element={<UserSetting />} />
                    <Route path="/product-image/:id" element={<ProductImage />} />
                    <Route path="/product-icon/:id" element={<Icon />} />
                    <Route path="*" element={<NoPage />} />
                </Routes>
        </>
    );
}
export default Auth;