import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import AuthUser from './components/auth/AuthUser';
import Guest from './navbar/guest';
import Auth from './navbar/auth';
import InstallPrompt from './InstallPrompt';

function App() {
  const { getToken } = AuthUser();
  if (!getToken()) {
    return (
      <div>
        <Guest />
        <InstallPrompt />
      </div>
    );
  } else {
    return (
      <div>
        <Auth />
        <InstallPrompt />
      </div>
    );
  }
}

export default App;
