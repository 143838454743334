import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
  <footer>
    {/* Footer-newsletter */}
    <div className="bg-primary py-3">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 mb-md-3 mb-lg-0">
            <div className="row align-items-center">
              <div className="col-auto flex-horizontal-center">
                <i className="ec ec-newsletter font-size-40" />
                <h2 className="font-size-20 mb-0 ml-3">
                 For Feedback
                </h2>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <form className="js-validate js-form-message">
              <label className="sr-only" htmlFor="subscribeSrEmail">
                Email address
              </label>
              <div className="input-group input-group-pill">
                <input
                  className="form-control border-0 height-40"
                 value='info@inventivetechnologies.com.bd'
                 readOnly
                />
                <div className="input-group-append">
                  <Link
                    to="mailto:info@inventivetechnologies.com.bd" 
                    className="btn btn-dark btn-sm-wide height-40 py-2"
                  >
                    Click Here
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    {/* End Footer-newsletter */}

    {/* Footer-bottom-widgets */}
    <div className="pt-8 pb-4 bg-gray-13" style={{background: 'radial-gradient(#fed70070, transparent)'}}>
      <div className="container mt-1">
        <div className="row">
          <div className="col-lg-5">
            <div className="mb-6">
              <a className="d-inline-block">
              <img className='footerLogo' src='/assets/logo.png'/>
              </a>
            </div>
            <div className="mb-4">
              <div className="row no-gutters">
                <div className="col-auto">
                  <i className="ec ec-support text-primary font-size-56" />
                </div>
                <div className="col pl-3">
                  <div className="font-size-13 font-weight-light">
                    Got questions? Call us 24/7!
                  </div>
                   <Link to="tel:+8801846844987"  className="font-size-20 text-gray-90"> +8801846 844 987</Link>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <h6 className="mb-1 font-weight-bold">Shop Address</h6>
              <address className="">
                180-181, Shohid Sayed Nazrul Islam Sharanee, <br/> Prime Tower (6th Floor),
                Bijoy Nagor, Paltan,<br/> Dhaka-1000, Bangladesh.
              </address>
            </div>
            <div className="my-4 my-md-4">
              <ul className="list-inline mb-0 opacity-7">
                <li className="list-inline-item mr-0">
                  <Link
                    className="btn font-size-20 btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                    to="#"
                  >
                    <span className="fab fa-facebook-f btn-icon__inner" />
                  </Link>
                </li>
                <li className="list-inline-item mr-0">
                  <Link
                    className="btn font-size-20 btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                    to="#"
                  >
                    <span className="fab fa-google btn-icon__inner" />
                  </Link>
                </li>
                <li className="list-inline-item mr-0">
                  <Link
                    className="btn font-size-20 btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                    to="#"
                  >
                    <span className="fab fa-twitter btn-icon__inner" />
                  </Link>
                </li>
                <li className="list-inline-item mr-0">
                  <Link
                    className="btn font-size-20 btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                    to="#"
                  >
                    <span className="fab fa-github btn-icon__inner" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3652.312109320523!2d90.4079328!3d23.7362471!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8f50153525f%3A0xce1fbd4e46fb40d1!2sPrime%20Tower!5e0!3m2!1sen!2sbd!4v1698228999165!5m2!1sen!2sbd"
                width="100%"
                height={300}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Footer-bottom-widgets */}

    {/* Footer-copy-right */}
    <div className="bg-primary py-2">
      <div className="container">
        <div className="flex-center-between d-block d-md-flex">
          <div className="mb-3 mb-md-0">
            <div className="text-gray-90">
              CopyRight © 2023
            </div>
          </div>
          <div className="text-md-right text-gray-90">All rights Reserved By <Link target='_blank' to="http://arzatech.com.bd/"  className='copy-link'>ArzaTech</Link></div>
        </div>
      </div>
    </div>
    {/* End Footer-copy-right */}

  </footer>
  
  <Link to="#"
    className="js-go-to u-go-to"
    data-position='{"bottom": 15, "right": 15 }'
    data-type="fixed"
    data-offset-top={400}
    data-compensation="#header"
    data-show-effect="slideInUp"
    data-hide-effect="slideOutDown"
  >
    <span className="fas fa-arrow-up u-go-to__inner" />
  </Link>
  </>
  );
};

export default Footer;
