import { Link, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect,useRef } from 'react';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Header from "./Header";

export default function AddCart() {
  const topOfPageRef = useRef(null);
  useEffect(() => {
    if (topOfPageRef.current) {
      topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  

const [menuData, setMenuData] = useState([]);
const fetchMenus = async () => {
	try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/menu`);
  const data = await response.json();
  setMenuData(data.data);
	}catch (error) {
  console.error('Error fetching user information:', error);
}
};

  const [cart, setCart] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    const cartFromLocalStorage = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(cartFromLocalStorage);
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    const count = cart.reduce((total, item) => total + 1, 0);
    const sum = cart.reduce((total, item) => total + item.quantity * item.sale_price, 0);
    setCartCount(count);
    setTotalSum(sum);
  }, [cart]);

    const addToCart = (product) => {
    const existingProductIndex = cart.findIndex((item) => item.id === product.id);
    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += 1;
      setCart(updatedCart);
    } else {
      setCart((prevCart) => [
        ...prevCart,
        { ...product, quantity: 1 },
      ]);
    }
    NotificationManager.success("Success...", "Add to Cart", 1000);
  };


  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
    setCart(updatedCart);
  };

  const updateQuantity = (productId, newQuantity) => {
    if (newQuantity >= 1) {
      const updatedCart = cart.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      );
      setCart(updatedCart);
    }
  };



//Filter and Product View
  const [filteredItems, setItems] = useState([]); 

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const { lavel_02 } = useParams();
  const { lavel_03 } = useParams();
  const { price } = useParams();


  const [search, setSearch] = useState('');
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };


const fetchItemsFromAPI = async (page) => {
      let searchValue = 'All';

    if (search && search.trim().length > 0) {
        searchValue = search;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/products-list-admin/${lavel_02}/${lavel_03}/${price}/${searchValue}/${page}`);
        const data = await response.json();
        setItems(data.data.data);
        setTotalPages(data.data.last_page);
        setCurrentPage(data.data.current_page);
        setTotalItems(data.data.total);
    } catch (error) {
        console.error("Error fetching data from the API:", error);
    }
};


  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    navigate(`/add-cart${selectedValue}/Price`);
  };

useEffect(() => {
  fetchMenus();
}, []);

useEffect(() => {
    fetchItemsFromAPI(currentPage);
}, [lavel_02,lavel_03, price, search]);

return(<>
  <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
     ref={topOfPageRef}
  >

<Header/>
    <div className="page-wrapper" style={{top:'57px'}}>
      <div className="container-fluid">
    

        <div className="row">

          <div className="col-md-12 col-lg-8 col-sm-12 scrollable-div">
            <div className="card white-box p-0"> 

<table>
  <tr>
  <td> <select className="form-control" id="menuSelect" onChange={handleSelectChange} value={selectedOption}>
          <option value='' selected="true" disabled="disabled">Select Menu</option>
        {menuData.map((item) => (
          <React.Fragment key={item.lavel_01}>
           <optgroup label={item.lavel_01}>
            {item.lavel_02.map((subItem) => (
              <React.Fragment key={subItem.lavel_02}>
                <option value={`/${subItem.lavel_02}/All`}>
                  &nbsp; &nbsp; {subItem.lavel_02}
                </option>
                {subItem.lavel_03.map((subSubItem) => (
                  <option
                    key={subSubItem.lavel_03}
                    value={`/${subItem.lavel_02}/${subSubItem.lavel_03}`}
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; {subSubItem.lavel_03}
                  </option>
                ))}
              </React.Fragment>
            ))}</optgroup>
          </React.Fragment>
        ))}
      </select></td>
  <td>    <input type="text" placeholder="Search ..."
      className="form-control" 	
    value={search}
    onChange={handleSearch}/></td>
  </tr>
</table>
     
 <div className="bg-gray-1 flex-center-between borders-radius-15 py-1">
<div className="px-0">
<div className='form-control'>
Price:   
<Link to={`/add-cart/${lavel_02}/${lavel_03}/Low`}  className={price === 'Low' ? 'link3' : 'link4'} > Low </Link> ||  
<Link to={`/add-cart/${lavel_02}/${lavel_03}/High`}  className={price === 'High' ? 'link3' : 'link4'}> High </Link>
</div>
</div>

  <div className="d-flex">
    <div className="ml-2">
        <div className='form-control'>
        Showing: {totalItems}
        </div>
    </div>
  </div>
</div>           
<div>
{filteredItems.map((product, idx) => (<>
<ul className="row list-unstyled products-group no-gutters">
   <li key={`items-${idx}`} className="col-12 col-md-12 col-wd-2 ">
      <div className="product-item__outer h-100">
         <div className="product-item__inner px-xl-4 p-3">
            <div className="product-item__body pb-xl-2">
               <table style={{width:'100%'}}>
                  <tr>
                     <td colSpan={2}>
                        <b>
                           {product.name}
                        </b>
                     </td>
                  </tr>

                  <tr>
                  <td style={{width:'25%'}}>
                        <img
                        src={`${process.env.REACT_APP_SERVER_URL}/public/images/${product.pic_1}`}
                           width={100}
                           /> 
                  </td>
                     <td style={{width:'75%'}}>
                        <div className="flex-center-between mb-1">
                              <div className="text-gray-100">BDT.{product.sale_price}</div>

                           <div className="d-none d-xl-block prodcut-add-cart">
                              <button
                                 onClick={() => addToCart(product)}
                              className="btn-add-cart btn-primary transition-3d-hover"
                              >
                              <i className="ec ec-add-to-cart" />
                              </button>
                           </div>
                        </div>
                        <div className="text-gray-20 mb-2 font-size-12">SKU: {product.code}</div>

                        <ul className="font-size-12 p-0 text-gray-110">
                           {product.key_feature.map((feature, featureIdx) => (
                           <li key={featureIdx} className="line-clamp-1 mb-1 list-bullet">
                              {feature.key_features}
                           </li>
                           ))}
                        </ul>
                     </td>
                  </tr>
               </table>
            </div>
         </div>
      </div>
   </li>
</ul>
</>))}
</div>

<nav
    className="d-md-flex justify-content-between align-items-center border-top pt-3"
    aria-label="Page navigation example"
    >

    <ul className="pagination mb-0 pagination-shop justify-content-center justify-content-md-start">
  &nbsp;&nbsp;  
  <li className="page-item">
    <button className="btn btn-primary link" onClick={() => fetchItemsFromAPI(currentPage - 1)} disabled={currentPage === 1}> Previous</button>
    </li>
    <li className="page-item">
    <button className="btn btn-primary link" onClick={() => fetchItemsFromAPI(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
    </li>
    </ul>
<div className="text-center text-md-left mb-3 mb-md-3">
    Showing Page {currentPage}/{totalPages} &nbsp;&nbsp;
    </div>
    </nav>
<hr/>
            </div>
          </div>

      <div className="col-lg-4 col-md-12 col-sm-12 scrollable-div">
      <div className="card white-box p-0 ">
      <div className="card-heading">
      <h3 className="box-title mb-0">Cart List  (<span>{cartCount}</span>)</h3>
      </div>
      <div className="card-body">

      <ul className="chatonline">
      <li className="border-bottom pb-3 mb-3">
      <div className="">
      <ul className="list-unstyled row mx-n2">
      {cart.map((item) => (
      <>

      <li className="px-2 col-auto">
      <img
      className="img-fluid cart-image"
      src={`${process.env.REACT_APP_SERVER_URL}/public/images/${item.pic_1}`}
      />
      </li>
      <li className="px-2 col">
      <Link to={`/product-details/${item.id}`}>
      <h5 className="text-blue font-size-14 font-weight-bold">
      {item.name}
      </h5>
      </Link>
      <span className="font-size-14">
      {item.quantity} x {item.sale_price}  &nbsp;&nbsp;
      <button style={{ border: 'none',width: '31px', borderRadius: '50px' }} onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
      &nbsp;&nbsp;
      <button style={{ border: 'none', width: '31px', borderRadius: '50px' }} onClick={() => updateQuantity(item.id, item.quantity - 1)}>-</button>
      <br/>BDT.{item.quantity * item.sale_price}
      </span>
      </li>
      <li className="px-2 col-auto">
      <div className="text-gray-90 link" onClick={() => removeFromCart(item.id)}>
      <i className="ec ec-close-remove" />
      </div>
      </li>
      <hr style={{width: '92%'}}/>
      </>
      ))}

      </ul>
      </div>
      {totalSum===0 ? <p>Cart is empty...</p> :<>
              <b className=' font-weight-bold font-size-16 '>
      TOTAL BDT.{totalSum}
      </b>
      </>
      }


      <br/>
      </li>
      </ul>
      {totalSum===0 ? <p></p> :<>
      <Link to="/place-order"
      className="btn btn-primary-dark-w ml-md-2 px-5 px-md-4 px-lg-5"
      >
      Checkout
      </Link>
       </>
      }

      </div>
      </div>
      </div>

        </div>
      </div>
    </div>
  </div>
      <NotificationContainer/>
</>)
}