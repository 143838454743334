  import { Link, useNavigate,useParams  } from "react-router-dom";
  import React, { useState, useEffect } from 'react';
  import axios from 'axios';

  const Invoice = () => {

  const handlePrint = () => {
  document.getElementById('printButton').style.display = 'none';
  window.print();
  document.getElementById('printButton').style.display = 'block';
  };
  const { order_id } = useParams();

  const [status, setStatus] = useState();
  const handleStatus = (event) => {
  setStatus(event.target.value);
  };

  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const submitStatus = async () => {
  setIsSaving(true);
  try {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/change-status`, {
  order_id,
  status,
  });

  const orderId = response.data.order_id;
  navigate(`/invoice/${orderId}`);
  handleSearch();
  } catch (error) {
  console.error('Network error:', error);
  } finally {
  setIsSaving(false);
  }
  };


  const [delivery_charge, setDeliveryCharge] =useState();

  const handleDeliveryCharge = (event)=> {
  setDeliveryCharge(event.target.value);
  }

  const [isSaving1, setIsSaving1] = useState(false);

  const submitDeliveryCharge = async () => {
  setIsSaving1(true);
  try {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/update-delivery-charge`, {
  order_id,
  delivery_charge,
  });
  setDeliveryCharge('');
  const orderId = response.data.order_id;
  navigate(`/invoice/${orderId}`);
  handleSearch();
  } catch (error) {
  console.error('Network error:', error);
  } finally {
  setIsSaving1(false);
  }
  };


  const [discount,setDiscount]=useState();
  const handelDiscount= (event)=>{
  setDiscount(event.target.value);
  }

  const [isSaving2, setIsSaving2] = useState(false);

  const submitDiscount = async () => {
  setIsSaving2(true);
  try {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/update-discount`, {
  order_id,
  discount,
  });
  setDiscount('');
  const orderId = response.data.order_id;
  navigate(`/invoice/${orderId}`);
  handleSearch();
  } catch (error) {
  console.error('Network error:', error);
  } finally {
  setIsSaving2(false);
  }
  };
  const [isSaving3, setIsSaving3] = useState(false);

  const submitPay = async () => {
  setIsSaving3(true);
  try {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/update-pay`, {
  order_id,
  pay,
  });
  setPay('');
  const orderId = response.data.order_id;
  navigate(`/invoice/${orderId}`);
  handleSearch();
  } catch (error) {
  console.error('Network error:', error);
  } finally {
  setIsSaving3(false);
  }
  };


  const [pay, setPay]=useState();
  const handlePay = (event)=>{
  setPay(event.target.value);
  }

  const [data, setData] = useState([]);

  const handleSearch = async () => {
  try {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/invoices-admin/search/${order_id}`);
  const responseData = await response.json();
  setData(responseData.data);
  } catch (error) {
  console.error('Error fetching user information:', error);
  }
  };
  useEffect(() => {
  handleSearch();
  }, []);
  return(
  <>

  <div id="printButton">

  <select
  style={{marginLeft:'60px'}}
  value={status} 
  onChange={handleStatus}>
  <option value=''>Status</option>
  <option value={0}>Processing</option>
  <option value={1}>On Delivery</option>
  <option value={2}>Delivered</option>
  </select>
  <button onClick={submitStatus}  disabled={isSaving}>Change</button>
  &nbsp;&nbsp;&nbsp;

  <input type="number"
  value={delivery_charge} 
  onChange={handleDeliveryCharge}
  placeholder="Delivery Charge"
  style={{width:'135px'}}
  />
  <button onClick={submitDeliveryCharge}  disabled={isSaving1}>Update</button>
  &nbsp;&nbsp;&nbsp;

  <input type="number"
  value={discount} 
  onChange={handelDiscount}
  placeholder="Discount Amount"
  style={{width:'145px'}}
  />
  <button onClick={submitDiscount}  disabled={isSaving2}>Update</button>
  &nbsp;&nbsp;&nbsp;

  <input type="number"
  value={pay} 
  onChange={handlePay}
  placeholder="Pay Amount"
  style={{width:'120px'}}
  />
  <button onClick={submitPay}  disabled={isSaving3}>Pay</button>
  &nbsp;&nbsp;&nbsp;

  <button onClick={handlePrint}>Print</button>
    &nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;

  <Link className="btn btn" to={`/challan/${order_id}`} target='_blank' style={{color:'blue'}}>Print Challan</Link>
  <br/>
  </div>
  <div className="container">
  <div className="row mb-8">
  <div className="col-xl-12 col-wd-12">

  {data.map((customerData) => (
  <div className="container-invoice" key={customerData.customer.id} >
  <div className="header">
  <img src="../assets/invheader.jpg" alt="Header" style={{width:'100%'}}/>
  </div>

  <div class="invoice-content">
  <div className="invoice-info">
  <div className="left">
  <p>{customerData.customer.customer_names}</p>
  <p>{customerData.customer.address}</p>
  </div>
  <div className="right">
  <p>Order Date: {customerData.customer.order_date}  </p>
  <p>Mobile: {customerData.customer.mobile}</p>
  </div>
  </div>
  {customerData.customer.orders.map((order) => (<>
  <div className="invoice-table">
  Invoice ID: {order.id}  || Status: 
  {Number (order.status) === 0 ? (
  <> Processing</>
  ) : Number (order.status) === 1 ? (
  <> On Delivery</>
  ) : Number (order.status) === 2 ? (
  <> Delivered</>
  ) : (
  <> Unknown Status</>
  )}

  <table key={order.id}>
  <tbody>
  <tr>
  <th style={{width:'12px'}}>#</th>
  <th>Items</th>
  <th style={{textAlign:'center'}}>Qty.</th>
  <th style={{textAlign:'right'}}>Price (BDT.)</th>
  <th style={{textAlign:'right'}}>Total (BDT.)</th>
  </tr>
  {order.order_items.map((item, index) => (
  <tr key={item.id}>
  <td>{index + 1}</td>
  <td>{item.name}</td>
  <td style={{textAlign:'center'}}>{item.qty}</td>
  <td style={{textAlign:'right'}}>{item.sale_price}</td>
  <td style={{textAlign:'right'}}>{item.qty*item.sale_price}</td>
  </tr>
  ))}
  </tbody>
  </table>
  </div>

  <div style={{textAlign: 'right'}}>
  <b>Subtotal: {order.order_items.reduce((acc, item) => acc + item.qty * item.sale_price, 0)}/=</b><br/>
  Delivery Charge: {order.delivery_charge}/=<br/>
  Discount: -{Number (order.discount)}/=<br/>
  Pay: {Number (order.pay)}/=<br/>
  <b style={{color:'red'}}>Due: {((order.order_items.reduce((acc, item) => acc + item.qty * item.sale_price, 0) + Number(order.delivery_charge))-(Number (order.pay)))-(Number (order.discount))}/=</b><br/>
  </div>
  </>))}
  </div>
  <p>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  ------------------------------------------ 
  Customer Signature
  &nbsp;  &nbsp;  &nbsp;
  ------------------------------------------ 
  Authorized Signature and Company Stamp
  </p>

  <div className="footer-invoice" style={{textAlign:'left'}}>
  1. Goods once sold will not be refunded or exchanged.<br/>
  2. Products under warranty will be repaired or replaced by the manufacturer company.<br/>
  3. The timing for the warranty process will be determined by the manufacturer company.<br/>
  </div>
<div className="fixed-footer">
  <img src="../assets/invfooter.jpg" alt="Footer" style={{ width: '100%' }} />
</div>
  </div>      
  ))} 

  </div>
  </div>
  </div>


  </>
  );
  };
  export default Invoice;